import React from 'react'

export default function ProductPrice(props) {

    let superscriptColor;
    let normalColor;
    let superscriptSize = '13px';
    let normalSize = '21px';

    if (props.theme === 'dark'){
        superscriptColor = 'rgb(250,250,250)'
        normalColor = 'white'
    }
    else if (props.theme === 'orange'){
        superscriptColor = 'rgb(255,165,48)'
        normalColor = 'var(--myOrange)'
    }
    else{
        superscriptColor = '#0F1111'
        normalColor = 'black'
    }

    if (props.letterSize === 'small'){
        superscriptSize = '12px';
        normalSize = '18px';
    }else if (props.letterSize === 'mobile'){
        superscriptSize = '8px';
        normalSize = '12px';
    }

    return (
        <div style={{ display: "flex", alignItems: "flex-start"  }}>
            <small style={{  fontSize: superscriptSize, color: superscriptColor }}>$</small>
            <span style={{ fontSize: normalSize, color: normalColor }}>{Math.floor(props.price).toLocaleString()}</span>
            <small style={{  fontSize: superscriptSize, color: superscriptColor }}>
              {(props.price - Math.floor(props.price)).toFixed(2).substring(2)}
            </small>
        </div>
    )
}
