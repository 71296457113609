import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TooltipContent from "./TooltipContent";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    // color: "rgba(255, 255, 255, 0.55)",
    fontSize: "1.25rem",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    // backgroundColor: "rgba(255, 255, 255, 0.55)",
    // backdropFilter: "blur(8px)",
    color: "rgba(0, 0, 0, 0.87)",
    width: 225,
    fontSize: theme.typography.pxToRem(12),
    // border: "1px solid #dadde9",
    boxShadow: "rgba(0, 0, 0, 0.5) 0px 15px 15px",
  },
}));

export default function RatingTooltip(props) {
  const { children, ...params } = props;

  return (
    <HtmlTooltip arrow title={<TooltipContent {...params} />}>
      {children}
    </HtmlTooltip>
  );
}
