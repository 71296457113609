import React, { useRef, useState } from "react";
import "./CartItem.css";
import ItemAmountButtons from "../QuickCart/ItemAmountButtons";
import LinkTextButton from "../UI/LinkTextButton";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";

export default function CartItem(props) {
  const imgRef = useRef();
  const [imgOrient, setImgOrient] = useState("horizontal");
  const isDesktop = useMediaQuery({ query: `(min-width: 800px)` });
  const history = useHistory()

  const handleImgLoad = () => {
    if (imgRef.current) {
      setImgOrient(
        imgRef.current.offsetHeight > imgRef.current.offsetWidth ? "vertical" : "horizontal"
      );
    }
  };

  const visitProductPage =() => {
    history.push(`/product/${props.item.id}`)
  }

  return (
    <div className="cart_item_main">
      <div className={`cart_item_left ${imgOrient}`} onClick={visitProductPage}>
        <img onLoad={handleImgLoad} ref={imgRef} src={`${props.item.img}/1.webp`} alt="product" />
      </div>
      <div className="cart_item_right">
        <div style={{ width: "100%" }}>
          <span style={{ fontSize: isDesktop ? 18 : 14 }}>{props.item.title}</span>
          <p style={{ fontSize: 14, color: "rgb(18 183 18)", marginTop: 10 }}>In Stock</p>
          <div style={{ display: "flex", margin: "10px 0px" }}>
            <ItemAmountButtons
              amount={props.item.amount}
              onAdd={props.onAdd}
              onRemove={props.onRemove}
              myFontSize={!isDesktop && "10px"}
              dimension={!isDesktop && "12px"}
            />
          </div>
          <div className='cart_item_links' style={{fontSize: isDesktop ? 14 : 12}}>
            <LinkTextButton onClick={props.onDelete}>Delete</LinkTextButton>
            <span style={{ color: "rgb(167,167,167)" }}>|</span>
            <LinkTextButton onClick={props.saveForLater}>Save for later</LinkTextButton>
            <span style={{ color: "rgb(167,167,167)" }}>|</span>
            {isDesktop &&
              <>
                <LinkTextButton onClick={visitProductPage}>Visit page</LinkTextButton>
                <span style={{ color: "rgb(167,167,167)" }}>|</span>
              </>
            }
          </div>
        </div>
        <div style={{ paddingLeft: 12, fontSize: isDesktop ? 18 : 14 }}>
          <strong>{`$${props.item.price.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}</strong>
        </div>
      </div>
    </div>
  );
}
