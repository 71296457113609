import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  const windowLocation = useLocation();
  let redirectPath;
  if (windowLocation.pathname) {
    redirectPath = `/login?redirect=${windowLocation.pathname.substring(1)}`;
  } else {
    redirectPath = "/login";
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? <Component {...props} /> : <Redirect to={redirectPath} />;
      }}
    ></Route>
  );
}
