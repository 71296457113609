const GetDepartureNameFromId = async (depId) => {
  let depName;
  try {
    const res = await fetch(`${process.env.REACT_APP_AZURE_API_URL}/getDepartmentName/${depId}`);
    await res.json().then((data) => {
      depName = data.name;
    });
  } catch (e) {
    depName = "";
  }
  return depName;
};

export default GetDepartureNameFromId;
