import React, { useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./SavedForLaterCard.css";
import SavedItem from "./SavedItem";

export default function SavefForLaterCard(props) {
  
  const savedForLaterCardRef = useRef();
  
  const cardTitle = `Saved for later (${props.items.length} ${props.items.length > 1 ? "items" : "item"})`;
  
  const items = props.items.map((item) => {
    return (
    <CSSTransition key={item.id} timeout={300} classNames="saveditem-animation">
      <SavedItem
        item={item}
        moveToCart={props.moveToCart.bind(null, item)}
        deleteSavedItem={props.deleteSavedItem.bind(null, item.id)}
      />
    </CSSTransition>
    );
  });

  return (
    <div className="saved__container">
      <div className="saved__content">
        <h2 style={{marginLeft: 10}}>{cardTitle}</h2>
        <div className="divider" />
        <TransitionGroup ref={savedForLaterCardRef} className="saved__items">
          {items}
        </TransitionGroup>
      </div>
    </div>
  );
}
