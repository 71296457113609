import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MySnackbar(props) {
  const { message, open, severity, anchorOrigin, handleClose, setError, autoHideDuration, ...params } = props;

  const autoHide = () => {
    setError((prevState) => {
      return { ...prevState, open: false };
    });
  };

  if (open) {
    setTimeout(autoHide, autoHideDuration);
  }

  return (
    <Snackbar {...params}
      anchorOrigin={anchorOrigin ? anchorOrigin : { vertical: "bottom", horizontal: "center" }}
      open={open}
    >
      <Alert onClose={handleClose} severity={severity ? severity : "error"} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
