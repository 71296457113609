import classes from "./PurchaseCard.module.css";
import { Card, CardContent } from "@mui/material";
import MyButtonContained from "../UI/MyButtonContained";
import { useCart } from "../contexts/CartProvider";

export default function PurchaseCard({ productData }) {
  const cartCtx = useCart();

  const addToCartHandler = () => {
    const itemQuantity = 1;
    cartCtx.addItem({ id: productData.id, price: productData.price, amount: itemQuantity });
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <p className={classes["price-text"]}>{`$${productData.price.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}`}</p>
        <p>FREE delivery</p>
        <p>
          <span style={{ color: "rgb(167 167 167)", marginRight: 5 }}>Arrives: </span>
          <b>{productData.arrivalDates}</b>
        </p>
        <MyButtonContained style={{ marginTop: 20 }} onClick={addToCartHandler} fullWidth>
          Add to Cart
        </MyButtonContained>
        <p>
          <span style={{ color: "rgb(167 167 167)", marginRight: 5 }}>Return policy: </span>
          <span> 60 days</span>
        </p>
      </CardContent>
    </Card>
  );
}
