import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MySpinner from "../UI/MySpinner";
import classes from "./LoadingProvider.module.css";

const LoadingContext = React.createContext({
  loading: true,
  setLoading: () => {},
});

export function useLoading() {
  return useContext(LoadingContext);
}

export default function LoadingProvider(props) {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [prevLocation, setPrevLocation] = useState(history);

  const loadingHandler = useCallback((loadingState) => {
    if (loadingState) {
      setIsLoading(loadingState);
    } else {
      // add a small delay when removing the spinner
      setTimeout(function () {
        setIsLoading(loadingState);
      }, 150);
    }
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== prevLocation.pathname) {
        if (prevLocation.pathname) {
          loadingHandler(true);
        }
        setPrevLocation(location);
      }
    });
  }, [history, loadingHandler, prevLocation]);

  const myLoadingContext = {
    loading: isLoading,
    setLoading: loadingHandler,
  };

  const childrenClass = `${classes.main} ${isLoading && classes.fade}`;

  return (
    <LoadingContext.Provider value={myLoadingContext}>
      {isLoading && <MySpinner />}
      <div className={childrenClass}>{props.children}</div>
    </LoadingContext.Provider>
  );
}
