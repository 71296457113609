import React, { useState } from "react";
import classes from "./RatingRow.module.css";

export default function RatingRow({ starValue, percentage }) {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const textClass = `${classes.text} ${hover && classes.text_hover}`;
  const barClass = `${classes.outer_bar} ${hover && classes.bar_hover}`;

  let convertedPercentage = `${(percentage * 100).toFixed(0)}%`;

  return (
    <div className={classes.main} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span className={textClass}>{`${starValue} star`}</span>
      <div className={barClass}>
        <div className={classes.inner_bar} style={{ width: convertedPercentage }}>
          &nbsp;
        </div>
      </div>
      <span className={textClass}>{convertedPercentage}</span>
    </div>
  );
}
