import React, { useReducer, useEffect, useContext } from "react";

const CartContext = React.createContext({
  items: [],
  itemsSavedForLater: [],
  totalAmount: 0,
  prevState: {},
  addItem: () => {},
  removeItem: () => {},
  deleteItem: () => {},
  saveItemForLater: () => {},
  deleteSavedItem: () => {},
  revertChanges: () => {},
});

export function useCart() {
  return useContext(CartContext);
}

const defaultCartState = { 
  items: JSON.parse(localStorage.getItem('cartItems')) || [],
  itemsSavedForLater: JSON.parse(localStorage.getItem('itemsSavedForLater')) || [],
};

const cartReducer = (state, action) => {
  let updatedSavedForLater = state.itemsSavedForLater;
  let cartPrevState = {...state}
  if (action.type === 'ADD_ITEM'){
    const existingItemIndex = state.items.findIndex(item => item.id === action.item.id);
    const existingItem = state.items[existingItemIndex];
    let updatedItems;
    
    if (existingItem) {
      const updatedItem = { ...existingItem, amount: existingItem.amount + action.item.amount } // update existin item's amount
      updatedItems = [...state.items]
      updatedItems[existingItemIndex] = updatedItem // change the old item with the updated item
    } else{
      updatedItems = state.items.concat(action.item);
    }

    // delete item from the savedForLater list
    const existingSavedForLaterItemIndex = updatedSavedForLater.findIndex(item => item.id === action.item.id)
    const existingSavedForLaterItem = updatedSavedForLater[existingSavedForLaterItemIndex];
    if(existingSavedForLaterItem){
      updatedSavedForLater = state.itemsSavedForLater.filter(item => item.id !== action.item.id)
    }
    return { items: updatedItems, itemsSavedForLater: updatedSavedForLater, prevState: cartPrevState }
  }
  else if (action.type === 'REMOVE_ITEM'){
    const existingItemIndex = state.items.findIndex(item => item.id === action.id)
    const existingItem = state.items[existingItemIndex]

    if (!existingItem){
      return { ...state }
    }

    let updatedItems;
    if (existingItem.amount === 1){
      updatedItems = state.items.filter(item => item.id !== action.id)
    }else{
      const updatedItem = {...existingItem, amount: existingItem.amount - 1}
      updatedItems = [...state.items]
      updatedItems[existingItemIndex] = updatedItem
    }
    return { items: updatedItems, itemsSavedForLater: updatedSavedForLater }
  } 
  else if (action.type === 'DELETE_ITEM' || action.type === 'SAVE_ITEM_FOR_LATER'){
    const existingItemIndex = state.items.findIndex(item => item.id === action.id)
    const existingItem = state.items[existingItemIndex]
    const updatedItems = state.items.filter(item => item.id !== action.id)

    if (action.type === 'SAVE_ITEM_FOR_LATER'){
      const existingSavedItemIndex = updatedSavedForLater.findIndex(item => item.id === action.id)
      if (existingItem && existingSavedItemIndex === -1){
        updatedSavedForLater = updatedSavedForLater.concat(existingItem);
      }
    }

    return { items: updatedItems, itemsSavedForLater: updatedSavedForLater, prevState: cartPrevState }
  }
  else if(action.type === 'DELETE_SAVED_ITEM'){
    updatedSavedForLater = state.itemsSavedForLater.filter(item => item.id !== action.id)
    return { items: state.items, itemsSavedForLater: updatedSavedForLater, prevState: cartPrevState }
  }
  else if( action.type === 'REVERT_CHANGES') {
    return {...action.prevState}
  }
  else if( action.type === 'COMPLETE_ORDER') {

    return { items: [], itemsSavedForLater: [], prevState: cartPrevState}
  }
  else{
    return defaultCartState;  
  }

};

export default function CartProvider(props) {

  const [cartState, dispatchCartAction] = useReducer(cartReducer, defaultCartState)
    
  const addItemToCartHandler = (item) => {
      dispatchCartAction({ type: 'ADD_ITEM', item: item })
  };
  const removeItemFromCartHandler = (id) => {
    dispatchCartAction({ type: 'REMOVE_ITEM', id: id })
  };
  const deleteItemFromCartHandler = (id) => {
    dispatchCartAction({ type: 'DELETE_ITEM', id: id })
  };
  const saveItemForLaterHandler = (id) => {
    dispatchCartAction({ type: 'SAVE_ITEM_FOR_LATER', id: id })
  };
  const deleteSavedItemHandler = (id) => {
    dispatchCartAction({ type: 'DELETE_SAVED_ITEM', id: id })
  };
  const revertChangesHandler = (prevState) => {
    dispatchCartAction({ type: 'REVERT_CHANGES', prevState })
  };
  const completeOrderHandler = () => {
    dispatchCartAction({ type: 'COMPLETE_ORDER' })
  };

  const myCartContext = {
    items: cartState.items,
    itemsSavedForLater: cartState.itemsSavedForLater,
    prevState: cartState.prevState,
    addItem: addItemToCartHandler,
    removeItem: removeItemFromCartHandler,
    deleteItem: deleteItemFromCartHandler,
    saveItemForLater: saveItemForLaterHandler,
    deleteSavedItem: deleteSavedItemHandler,
    revertChanges: revertChangesHandler,
    completeOrder: completeOrderHandler,
  };

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartState.items));
  }, [cartState.items])

  useEffect(() => {
    localStorage.setItem('itemsSavedForLater', JSON.stringify(cartState.itemsSavedForLater));
  }, [cartState.itemsSavedForLater])

  return (
    <CartContext.Provider value={myCartContext}>
      {props.children}
    </CartContext.Provider>
  );
}
