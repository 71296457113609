import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import classes from "./ItemAmountButtons.module.css";
import { styled } from "@mui/material/styles";
import { Fab } from "@mui/material";

const StyledAddIcon = styled(AddIcon)(() => ({
  color: "rgb(51,51,51)",
}));

const StyledRemoveIcon = styled(RemoveIcon)(() => ({
  color: "rgb(51,51,51)",
}));

const StyledFab = styled(Fab)(() => ({
  height: 20,
  minHeight: 20,
  lineHeight: '20px',
  verticalAlign: 'middle',
  width: 20,
  backgroundColor: 'var(--myOrange)',
  '@media (hover: none)':{
    '&:hover':{
      backgroundColor: 'var(--myOrange)',
    },
    '&:active':{
      transition: 'transform .1s ease-in-out',
      transform: 'scale(0.8)'
    }
  },
  '@media (hover: hover)':{
    '&:hover':{
      transition: 'transform .1s ease-in-out',
        transform: 'scale(1.1)',
        backgroundColor: 'rgb(255,190,105)',
    },
    '&:active':{
      transition: 'transform .1s ease-in-out',
      transform: 'scale(0.8)',
    }
},

}));

export default function ItemAmountButtons(props) {

  let myStyle = {};
  if(props.dimension){
    myStyle = { lineHeight: props.dimension,width: props.dimension, height: props.dimension, minHeight: props.dimension}
  }

  return (
    <div className={classes["item__actions"]}>
      <StyledFab style={myStyle} onClick={props.onRemove}>
        <StyledRemoveIcon fontSize={props.myFontSize? props.myFontSize:"small"} />
      </StyledFab>
      {props.amount}
      <StyledFab style={myStyle} onClick={props.onAdd}>
        <StyledAddIcon fontSize={props.myFontSize? props.myFontSize:"small"} />
      </StyledFab>
    </div>
  );
}
