export default function FilterSearchResults(data, options) {
  if (options.rating) {
    data = data.filter((el) => el.rating >= options.rating);
  }

  if (options.price) {
    data = data.filter((el) => el.price >= options.price[0] && el.price <= options.price[1]);
  }

  return data;
}
