import React, { useState, useEffect } from "react";
import MyButtonContained from "../UI/MyButtonContained";
import "./Cart.css";
import CartItem from "./CartItem";
import SavedForLaterCard from "./SavedForLaterCard";
import CartSnackbar from "./CartSnackbar";
import { useMediaQuery } from "react-responsive";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useCart } from "../contexts/CartProvider";
import { useLoading } from "../contexts/LoadingProvider";
import { useHistory } from "react-router-dom";
import MyCheckbox from "../UI/MyCheckbox";
import reqOptions from "../Helpers/POSTRequestOptions";

export default function Cart() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [itemsSavedForLater, setItemsSavedForLater] = useState([]);
  const [noItems, setNoItems] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const cartCtx = useCart();
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: `(min-width: 800px)` });
  const { setLoading: setPageLoading } = useLoading();

  useEffect(() => {
    document.title = `Shopping Cart - This is a demo website`;
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_AZURE_API_URL}/updateCartData`, reqOptions({ cartData: cartCtx.items }))
      .then((res) => res.json())
      .then((data) => {
        setCartItems(data.cartData);
        setCartTotal(data.cartTotal);
      })
      .then(() => {
        setPageLoading(false);
      });
  }, [cartCtx.items, setPageLoading]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_AZURE_API_URL}/updateCartData`, reqOptions({ cartData: cartCtx.itemsSavedForLater }))
      .then((res) => res.json())
      .then((data) => {
        setItemsSavedForLater(data.cartData);
      });
  }, [cartCtx.itemsSavedForLater]);

  useEffect(() => {
    setNoItems(
      cartItems.reduce((cur, item) => {
        return cur + item.amount;
      }, 0)
    );
  }, [cartItems]);

  const cartAddItemHandler = (item, e) => {
    cartCtx.addItem({ ...item, amount: 1 });
  };
  const cartRemoveItemHandler = (id) => {
    cartCtx.removeItem(id);
  };
  const cartDeleteItemHandler = (id) => {
    cartCtx.deleteItem(id);
    setSnackbarOpen(true);
  };
  const saveItemForLaterHandler = (id) => {
    cartCtx.saveItemForLater(id);
  };
  const deleteSavedItemHandler = (id) => {
    cartCtx.deleteSavedItem(id);
    setSnackbarOpen(true);
  };

  const pageItems = cartItems.map((item) => {
    return (
      <CSSTransition key={item.id} timeout={300} classNames="item-animation">
        <CartItem
          item={item}
          onRemove={cartRemoveItemHandler.bind(null, item.id)}
          onAdd={cartAddItemHandler.bind(null, item)}
          onDelete={cartDeleteItemHandler.bind(null, item.id)}
          saveForLater={saveItemForLaterHandler.bind(null, item.id)}
        />
      </CSSTransition>
    );
  });

  const subTotal = (
    <span style={{ fontSize: 18 }}>
      Subtotal ({noItems} {noItems === 1 ? "item" : "items"}) :{" "}
      <strong>
        {`$${Math.abs(cartTotal).toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
      </strong>
    </span>
  );

  const checkoutCard = (
    <div className="checkout__container">
      {subTotal}
      <div>
        <MyCheckbox size="small" />
        <span>This order contains a gift</span>
      </div>
      <div className="checkout__button">
        <MyButtonContained
          style={{ width: "100%", marginRight: 20 }}
          onClick={() => {
            history.push("/checkout?currentStep=shipping");
          }}
        >
          Proceed to checkout
        </MyButtonContained>
      </div>
    </div>
  );

  return (
    <div className="cart__main">
      <CartSnackbar
        cartCtx={cartCtx}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
      <div className="cart__left">
        <div className="cart__container">
          <div className="cart__details">
            <div className="cart__header">
              <h2 style={{ color: "white", marginBottom: 5, padding: 10 }}>
                {noItems === 0 ? "Your Cart is empty." : "Shopping Cart"}
              </h2>
              <span style={{ marginRight: 3 }}>Price</span>
            </div>
            <TransitionGroup>{pageItems}</TransitionGroup>
            <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
              {subTotal}
            </div>
          </div>
        </div>
        {!isDesktop && (
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 20 }}>
            {checkoutCard}
          </div>
        )}
        <SavedForLaterCard
          items={itemsSavedForLater}
          moveToCart={cartAddItemHandler}
          deleteSavedItem={deleteSavedItemHandler}
        />
      </div>

      <div className="cart__right">{isDesktop && checkoutCard}</div>
    </div>
  );
}
