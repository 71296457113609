import React from "react";
import "./LinkTextButton.css";

export default function LinkTextButton(props) {
  const { children, onClick, className, color, ...params } = props;
  return (
    <span
      {...params}
      className={
        className
          ? className
          : color === "white"
          ? "link__text__white"
          : color === "gray"
          ? "link__text__gray"
          : color === "orange"
          ? "link__text__orange"
          : color === "lightGray"
          ? "link__text__lightGray"
          : "link__text"
      }
      onClick={onClick}
    >
      {children}
    </span>
  );
}
