import React from "react";
import classes from "./QuickCartItem.module.css";
import ItemAmountButtons from "./ItemAmountButtons";
import { useMediaQuery } from "react-responsive";
// import ProductPrice from '../UI/ProductPrice';

export default function QuickCartItem(props) {
  const isDesktop = useMediaQuery({ query: `(min-width: 800px)` });

  return (
    <div className={classes["item__main"]}>
      <div className={classes["item__details"]}>
        <p fontSize={16}>{props.title}</p>
        <div className={classes["item__price"]}>
          {/* <ProductPrice  price={props.price} theme='orange' letterSize='small' /> */}
          <div className={classes["quantity__buttons"]}>
            <ItemAmountButtons
              amount={props.amount}
              onAdd={props.onAdd}
              onRemove={props.onRemove}
              myFontSize={!isDesktop && "10px"}
              dimension={!isDesktop && "12px"}
            />
          </div>
        </div>
      </div>
      <div>
        <h3>
          ${(props.price * props.amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}
        </h3>
        {/* <ProductPrice  price={props.price*props.amount} theme='light' /> */}
      </div>
    </div>
  );
}
