import React, { useCallback, useEffect, useState } from "react";
import { useLoading } from "../contexts/LoadingProvider";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./Checkout.module.css";
import Breadcrumb from "./Breadcrumb";
import Shipping from "./Shipping";

import { Divider } from "@mui/material";
import { useMediaQuery } from "react-responsive";

import { CSSTransition } from "react-transition-group";
import "./FormAnimation.css";
import Payment from "./Payment";
import CartSummary from "./CartSummary";
import { useCart } from "../contexts/CartProvider";

export default function Checkout() {
  const { setLoading: setPageLoading } = useLoading();
  const windowLocation = useLocation();
  const [currentStep, setCurrentStep] = useState("");
  const [formHeight, setFormHeight] = useState();
  const isMobile = useMediaQuery({ query: `(max-width: 450px)` });
  const isDesktop = useMediaQuery({ query: `(max-width: 969px)` });
  const cartCtx = useCart();
  const history = useHistory();

  const calcHeight = useCallback((el) => {
    setFormHeight(el.offsetHeight + 40);
  }, []);

  useEffect(() => {
    setPageLoading(false);
  }, [setPageLoading]);

  useEffect(() => {
    if (cartCtx.items.length === 0) {
      history.push("/");
      return;
    }
    const params = new URLSearchParams(windowLocation.search);
    if (params.get("currentStep") === null) {
      params.append("currentStep", "shipping");
      setCurrentStep("shipping");
    } else {
      setCurrentStep(params.get("currentStep"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowLocation, history]);

  const left = (
    <div className={classes.left} style={{ height: formHeight }}>
      <Breadcrumb activeStep={currentStep} />
      <Divider style={{ marginTop: "0px" }} />

      <CSSTransition
        in={currentStep === "shipping"}
        timeout={800}
        classNames="form-primary"
        onEntering={calcHeight}
        unmountOnExit
      >
        <div className={classes.form}>
          <Shipping isMobile={isMobile} />
        </div>
      </CSSTransition>

      <CSSTransition
        in={currentStep === "payment"}
        timeout={800}
        classNames="form-secondary"
        onEntering={calcHeight}
        unmountOnExit
      >
        <div className={classes.form}>
          <Payment
            setPageLoading={setPageLoading}
            cartCtx={cartCtx}
            isMobile={isMobile}
            calcHeight={calcHeight}
          />
        </div>
      </CSSTransition>
    </div>
  );

  const right = (
    <div className={classes.right}>
      <CartSummary setPageLoading={setPageLoading} cartCtx={cartCtx} />
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        {!isDesktop ? (
          <>
            {left}
            {right}
          </>
        ) : (
          <>
            {right}
            {left}
          </>
        )}
      </div>
    </div>
  );
}
