import React, { useEffect } from "react";
import MyTextField from "../UI/MyTextField";
import { InputAdornment, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import useSimpleText from "../UI/FormComponents/useSimpleText";
import HelpIcon from "@mui/icons-material/Help";
import classes from "./Checkout.module.css";
import LinkTextButton from "../UI/LinkTextButton";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { useHistory } from "react-router-dom";
import AddressForm from "./AddressForm";
import createProps from "../Helpers/simpleTextProps";
import inputNumbersOnly from "../Helpers/inputNumbersOnly";
import { PhoneMask } from "./TextFieldMasks";

export default function Shipping({ isMobile }) {
  const [firstName, firstNameHandler] = useSimpleText(createProps("John"));
  const [lastName, lastNameHandler] = useSimpleText(createProps("Doe"));
  const [email, emailHandler] = useSimpleText(createProps("john.doe@amazon.com"));
  const [phone, phoneHandler] = useSimpleText(createProps("015555552368"));
  const [address, addressHandler] = useSimpleText(createProps("410 Terry Ave N"));
  const [address2, address2Handler] = useSimpleText(createProps(""));
  const [country, countryHandler] = useSimpleText(createProps("United States"));
  const [state, stateHandler] = useSimpleText(createProps("WA"));
  const [city, cityHandler] = useSimpleText(createProps("Seattle"));
  const [postalCode, postalCodeHandler] = useSimpleText(
    createProps("98109", null, inputNumbersOnly)
  );

  const history = useHistory();

  useEffect(() => {
    document.title = `Checkout - Shipping details`;
  }, []);

  const textfieldSize = isMobile ? "small" : "";

  const goToPayment = () => {
    history.push("/checkout?currentStep=payment");
  };

  const returnToCart = () => {
    history.push("/cart");
  };

  return (
    <>
      <div>
        <Typography variant="h5">Personal Information</Typography>
      </div>
      <div className={classes.row}>
        <MyTextField
          id="first-name-input"
          InputProps={{ autoComplete: "given-name" }}
          value={firstName}
          onChange={firstNameHandler}
          style={{ width: "100%", marginRight: "1.5%" }}
          label="First name"
          size={textfieldSize}
          required
        />
        <MyTextField
          id="last-name-input"
          InputProps={{ autoComplete: "family-name" }}
          value={lastName}
          onChange={lastNameHandler}
          style={{ width: "100%" }}
          label="Last name"
          size={textfieldSize}
          required
        />
      </div>
      <div className={classes.row}>
        <MyTextField
          InputProps={{ autoComplete: "email" }}
          value={email}
          onChange={emailHandler}
          style={{ width: "100%", flex: 0.6, marginRight: "1.5%" }}
          label="Email"
          size={textfieldSize}
          required
        />
        <MyTextField
          InputProps={{
            autoComplete: "tel",
            inputComponent: PhoneMask,
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="In case we need to contact you">
                  <HelpIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          value={phone}
          onChange={phoneHandler}
          style={{ width: "100%", flex: 0.4 }}
          label="Phone (optional)"
          size={textfieldSize}
        />
      </div>
      <div>
        <Typography variant="h5">Shipping Address</Typography>
      </div>
      <AddressForm
        address={address}
        addressHandler={addressHandler}
        address2={address2}
        address2Handler={address2Handler}
        country={country}
        countryHandler={countryHandler}
        state={state}
        stateHandler={stateHandler}
        city={city}
        cityHandler={cityHandler}
        postalCode={postalCode}
        postalCodeHandler={postalCodeHandler}
        textfieldSize={textfieldSize}
      />
      <div className={classes.buttons}>
        <div className={classes.return}>
          <LinkTextButton color="gray" onClick={returnToCart}>
            <div className={classes.return}>
              <ArrowLeftIcon />
              {isMobile ? "Return" : "Return to cart"}
            </div>
          </LinkTextButton>
        </div>
        <div className={classes.return}>
          <LinkTextButton color="orange" onClick={goToPayment}>
            <div className={classes.return}>
              {isMobile ? "Continue" : "Continue to payment"}
              <ArrowRightIcon />
            </div>
          </LinkTextButton>
        </div>
      </div>
    </>
  );
}
