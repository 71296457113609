import React, { useEffect } from "react";
import { useLoading } from "../contexts/LoadingProvider";
import classes from "./Account.module.css";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

const cards = [
  {
    title: "Your Orders",
    text: "Track, return, or buy things again",
    img: "/images/account/order.webp",
    link: "/account/orders",
  },
  {
    title: "Login & Security",
    text: "Edit login, name, and mobile number",
    img: "/images/account/security.webp",
  },
  { title: "Prime", text: "View benefits and payment settings", img: "/images/account/prime.webp" },
  {
    title: "Gift cards",
    text: "View balance, redeem, or reload cards",
    img: "/images/account/gift_cards.webp",
  },
  {
    title: "Your payments",
    text: "Manage payment methods and settings, view all transactions",
    img: "/images/account/payments.webp",
  },
  {
    title: "Your Profiles",
    text: "Manage, add, or remove user profiles for personalized experiences",
    img: "/images/account/profiles.webp",
  },
  {
    title: "Digital Services and Device Support",
    text: "Troubleshoot device issues",
    img: "/images/account/digital_services.webp",
  },
  {
    title: "Your Messages",
    text: "View messages to and from Amazon, sellers, and buyers",
    img: "/images/account/messages.webp",
  },
  {
    title: "Archived orders",
    text: "View and manage your archived orders",
    img: "/images/account/archived_orders.webp",
  },
  {
    title: "Your Lists",
    text: "View, modify, and share your lists, or create new ones",
    img: "/images/account/lists.webp",
  },
  { title: "Customer Service", text: "", img: "/images/account/customer_service.webp" },
];

export default function Account() {
  const { setLoading: setPageLoading } = useLoading();
  const history = useHistory();

  useEffect(() => {
    setPageLoading(false);
  }, [setPageLoading]);

  const handleClick = (link) => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <h1>Your Account</h1>
        <div className={classes.grid}>
          {cards.map((el, idx) => {
            return (
              <div key={idx} className={classes.item} onClick={handleClick.bind(null, el.link)}>
                {el.title === "Prime" ? (
                  <img
                    src={el.img}
                    alt={el.title}
                    style={{ backgroundColor: "white", borderRadius: "8px", width: 90, height: 50 }}
                  />
                ) : (
                  <img src={el.img} alt={el.title} />
                )}
                <div className={classes.item__content}>
                  <Typography variant="h2" style={{ fontSize: "1.25rem" }}>
                    {el.title}
                  </Typography>
                  <Typography variant="subtitle1." style={{ color: "#d5d5d5", marginTop: ".5rem" }}>
                    {el.text}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
