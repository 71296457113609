import { Checkbox } from "@mui/material";
import React from "react";
import classes from './Checkbox.module.css'

export default function MyCheckbox(props) {
  const { children, ...params } = props;
  return (
    <Checkbox
      className={classes.checkbox}
      style={{ color: 'var(--myOrange)'}}
      {...params}
    >
      {children}
    </Checkbox>
  );
}
