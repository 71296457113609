import React, { useState, useEffect } from "react";
import "./PriceFilter.css";
import MyTextField from "../../UI/MyTextField";
import MySlider from "../../UI/MySlider";

const handleKeyDown = (e) => {
  if (e.code === "Enter" || e.keyCode === "13" || e.keyCode === 13) {
    e.target.blur();
  }
};

const handleInputPrice = (event, setStateFunction) => {
  let newValue = +event.target.value;
  if (isNaN(newValue)) { return }
  setStateFunction(newValue);
}

export default function PriceFilter({ filterOptions, minMaxPrice, setFilterOptions }) {

  const minDistance = Math.ceil((minMaxPrice.max - minMaxPrice.min) * 0.12);
  const { price } = filterOptions
  const [inputStart, setInputStart] = useState(minMaxPrice.min);
  const [inputEnd, setInputEnd] = useState(minMaxPrice.max);

  // handle when filters are reset
  useEffect(() => {
    setInputStart(price[0])
    setInputEnd(price[1])
  }, [price])

  const handleSliderChange = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    let inputStart, inputEnd;
    if (activeThumb === 0) {
      inputStart = Math.min(newValue[0], price[1] - minDistance);
      inputEnd = price[1];
    } else {
      inputStart = price[0];
      inputEnd = Math.max(newValue[1], price[0] + minDistance);
    }
    
    setFilterOptions(prevState => {
      return { ...prevState , price: [inputStart, inputEnd]}
    })
  };

  const handleStartPrice = (event) => { handleInputPrice(event, setInputStart) };
  const handleEndPrice = (event) => { handleInputPrice(event, setInputEnd) };

  const handleStartPriceBlur = () => {
    let newValue = Math.min(inputStart, price[1] - minDistance);
    newValue = Math.max(newValue, minMaxPrice.min)
    setInputStart(newValue);
    setFilterOptions(prevState => {
      return { ...prevState, price: [newValue, prevState.price[1]]}
    })
  };

  const handleEndPriceBlur = () => {
    let newValue = Math.max(inputEnd, price[0] + minDistance);
    newValue = Math.min(newValue, minMaxPrice.max)
    setInputEnd(newValue);
    setFilterOptions(prevState => {
      return { ...prevState, price: [prevState.price[0], newValue]}
    })
  };

  return (
    <div className="pricefilter__container">
      <b style={{ marginBottom: 15 }}>Pricing</b>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div className="pricefilter__inputs">
          <MyTextField value={inputStart} style={{ paddingRight: 10 }}
            size="small" label="Start"
            onChange={handleStartPrice}
            onBlur={handleStartPriceBlur}
            onKeyDown={handleKeyDown}
          />
          <MyTextField value={inputEnd}
            size="small" label="End" 
            onBlur={handleEndPriceBlur}
            onChange={handleEndPrice}
            onKeyDown={handleKeyDown}
          />
        </div>
        <MySlider value={price}
          getAriaValueText={val => `$${val}`}
          min={minMaxPrice.min} max={minMaxPrice.max}
          onChange={handleSliderChange}
          step={(minMaxPrice.max - minMaxPrice.min) > 100 ? Math.min(5, minDistance): 1}
          style={{ width: '97%' }}
          disableSwap
        />
      </div>
    </div>
  );
}
