import { Card, CardContent, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import classes from "./Login.module.css";
import { Link } from "react-router-dom";
import MyTextField from "../UI/MyTextField";
import MyButtonContained from "../UI/MyButtonContained";
import MyTextButton from "../UI/MyTextButton";
import { useAuth } from "../contexts/AuthProvider";
import { useHistory, useLocation } from "react-router";
import PasswordInput from "./PasswordInput";
import { useLoading } from "../contexts/LoadingProvider";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputPass, setInputPass] = useState("");
  const [loginError, setLoginError] = useState("");
  const [redirectPath, setRedirectPath] = useState("/");
  const history = useHistory();
  const windowLocation = useLocation();
  const authCtx = useAuth();
  useLoading().setLoading(false);

  useEffect(() => {
    document.title = `Login Page - This is a demo website`;
    const params = new URLSearchParams(windowLocation.search);
    if (params.get("redirect") !== null) {
      setRedirectPath(`/${params.get("redirect")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoginError("");
    try {
      await authCtx.login(inputEmail, inputPass);
      setLoading(false);
      history.push(redirectPath);
    } catch (error) {
      console.log(error);
      setLoading(false);
      let firebaseMessage = error.message.split(" ").slice(1, -1).join(" ").replace(".", "");
      if (firebaseMessage.includes("no user record")) {
        firebaseMessage = "We cannot find an account with that email address";
      } else if (firebaseMessage.includes("password is invalid")) {
        firebaseMessage = "Wrong password";
      }
      setLoginError("Authentication failed. " + firebaseMessage);
    }
  };

  const handleEmailChange = (e) => {
    setInputEmail(e.target.value);
  };

  const handlePassChange = (e) => {
    setInputPass(e.target.value);
  };

  const navLogo = (
    <Link to="/">
      <img
        style={{ width: "20vw", maxWidth: 200, minWidth: 100, marginBottom: 20 }}
        src={"/images/amazon_logo.png"}
        alt="company logo"
      />
    </Link>
  );

  return (
    <div className={classes.container}>
      {navLogo}
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div style={{ display: "flex" }}>
            <h1>Sign-in</h1>
            {loading && <CircularProgress style={{ marginLeft: 20, color: "var(--myOrange)" }} />}
          </div>
          <form onSubmit={handleLogin}>
            <MyTextField
              // error
              // helperText="Incorrect"
              InputProps={{
                autoComplete: "username email",
              }}
              value={inputEmail}
              onChange={handleEmailChange}
              style={{ marginTop: 30, width: "100%" }}
              label="Email"
              required
            />
            <PasswordInput
              value={inputPass}
              onChange={handlePassChange}
              style={{ marginTop: 20, width: "100%" }}
              label="Password"
            />
            {loginError && <p style={{ color: "#ff3915", marginTop: 20 }}>{loginError}</p>}
            <MyButtonContained style={{ marginTop: 30 }} type="submit" fullWidth>
              Login
            </MyButtonContained>
            <MyTextButton component={Link} to="/signup" style={{ marginTop: 10 }} fullWidth>
              Create an account
            </MyTextButton>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
