import React, { useState, useEffect } from "react";
import classes from "./ProductPage.module.css";
import { useParams, useHistory } from "react-router-dom";
import MyRating from "../UI/MyRating";
import LinkTextButton from "../UI/LinkTextButton";
import MySnackbar from "../UI/MySnackbar";
import Thumbnails from "./Thumbnails";
import ProductSpecs from "./ProductSpecs";
import PurchaseCard from "./PurchaseCard";
import { useLoading } from "../contexts/LoadingProvider";

const handleThumbnails = (data) => {
  let tempThumbnails = [{img: '1.webp', selected: true}]
  tempThumbnails = tempThumbnails.concat(
    data.map(item => {
      return {img: item, selected: false}
    })
  )
  tempThumbnails = tempThumbnails.filter(el => el.img !== '')
  return tempThumbnails
}

const handleResponse = (res) => {
  if (!res.ok)
    return res.text().then((text) => {
      throw Error(text);
    });
  else return res.json();
}

export default function ProductPage() {
  const { productId } = useParams();
  const [productData, setProductData] = useState();
  const [thumbnails, setThumbnails] = useState([]);
  const [mainImage, setMainImage] = useState('')
  const [error, setError] = useState({ open: false, msg: "" });
  const history = useHistory();
  const {setLoading: setPageLoading} = useLoading();
  


  useEffect(() => {
    setError({ open: false, msg: "" });
    setProductData()
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_AZURE_API_URL}/getProduct/${productId}`)
        .then((res) => { return handleResponse(res); })
        .then((item) => {
          setProductData(item.data);
          setMainImage(`${item.data.img}/1.webp`)
          setThumbnails(handleThumbnails(item.data.thumbnails))
          document.title = `Amazon Clone: ${item.data.title}`;
        })
        .catch((e) => {
          setError({ open: true, msg: e.message });
          // setTimeout(() => { history.push('/') },5000)
        })
        .then( () => { setPageLoading(false) });
    };
    fetchData();
  }, [productId, setPageLoading]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError((prevState) => {
      return { ...prevState, open: false };
    });
  };

  const departmentLinkHandler = () => {
    history.push(`/search?dep=${productData.departmentId}`);
  };



  return (
    <div className={classes["product__main"]}>
      <MySnackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{top: 'min(120px, 17vh)'}}
        open={error.open}
        severity="error"
        message={error.msg}
        setError={setError}
        handleClose={handleClose}
        autoHideDuration={5000}
      />
      {productData && (
        <div className={classes["product__container"]}>
          <div className={classes["product__left"]}>
            <div style={{ fontSize: 14, marginLeft: "5%", marginTop: "3%" }}>
              <LinkTextButton className={classes["department-link"]} onClick={() => {history.push("/");}}>
                Home
              </LinkTextButton>
              <span style={{ margin: '0px 5px' }}>{">"}</span>
              <LinkTextButton className={classes["department-link"]} onClick={departmentLinkHandler}>
                {productData.departmentName}
              </LinkTextButton>
            </div>
            <div className={classes["product__images"]}>
              <Thumbnails data={thumbnails} productPath={productData.img} setThumbnails={setThumbnails} setMainImage={setMainImage} />
              <img  src={mainImage} width="70%" alt={productData.title} />
            </div>
          </div>

          <div className={classes["product__info"]}>
            <h2>{productData.title}</h2>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <MyRating
                style={{ marginRight: 10 }}
                size="small"
                value={productData.rating}
                ratingsList={productData.ratingsList}
                readOnly
                precision={0.1}
              />
              <LinkTextButton>500 ratings</LinkTextButton>
            </div>
            
            <p id='price'>
              <span style={{ color: "rgb(167 167 167)", marginRight: 10 }}>
                Price:
              </span>
              <span className={classes["price-text"]}>
                {`$${productData.price.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
              </span>
            </p>

            <ProductSpecs data={productData.productSpecs} />
            {productData.aboutProduct.length > 0 &&
              <div className={classes['product__description']}>
                <h3 style={{marginBottom: 10}}>About this item</h3>
                <ul>
                    {productData.aboutProduct.map( item => <li key={item.substring(1, 10)}>{item}</li>)}
                </ul>
              </div>
            }
          </div>

          <div className={classes["product__right"]}>
            <PurchaseCard productData={productData} />
          </div>
        </div>
      )}
    </div>
  );
}
