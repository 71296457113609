import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export default function MyButtonContained(props) {
  const StyledButton = styled(Button)(() => ({
    backgroundColor: "var(--myOrange)",
    color: props.textColor ? props.textColor : "rgb(51,51,51)",
    "@media (hover: hover)": {
      "&:hover": {
        backgroundColor: "var(--lighterOrange)",
        color: props.textColor ? props.textColor : "rgb(51,51,51)",
      },
      "&:active": {
        backgroundColor: "var(--lighterOrange)",
        transition: 'transform .1s ease-in-out',
        transform: 'scale(0.92)',
      },
    },
    "@media (hover: none)": {
      "&:hover": {
        backgroundColor: "var(--myOrange)",
        color: props.textColor ? props.textColor : "rgb(51,51,51)",
      },
      "&:active": {
        backgroundColor: "var(--lighterOrange)",
      },
    },
  }));

  const { children, textColor, ...params } = props;

  return (
    <StyledButton variant="contained" {...params}>
      {children}
    </StyledButton>
  );
}
