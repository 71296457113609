import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import DepartmentCard from "./DepartmentCard";
import "./Home.css";
import HomeProduct from "./HomeProduct";
import HorizontalScrollContainer from "./HorizontalScrollContainer";
import { useAuth } from "../contexts/AuthProvider";
import { useLoading } from "../contexts/LoadingProvider";

export default function Home() {
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [productsError, setProductsError] = useState("");
  const [departments, setDepartments] = useState([]);
  const [departmentsLoading, setDepartmentsLoading] = useState(true);
  const [departmentsError, setDepartmentsError] = useState("");
  const [imgLoaded, setImgLoaded] = useState(false);
  const authCtx = useAuth()
  const {setLoading: setPageLoading} = useLoading();


  const bkgImg = authCtx.currentUser && false ? "images/amazon_back_bezos.png" : "/images/amazon_back.jpg";

  const handleProductsAPI = (data) => {
    let tempProducts = data.products.map((el) => {
      return (<HomeProduct key={el.id} id={el.id}
                title={el.title} price={el.price} img={el.img}
                rating={el.rating} ratingsList={el.ratingsList}
                />);
    });
    setProducts(tempProducts);
    setProductsLoading(false);
    setProductsError("");
  };

  const handleDepartmentsAPI = (data) => {
    let tempDeps = data.departments.map((el) => {
      return (<DepartmentCard key={el.id} id={el.id}
                name={el.name} img={el.img} />);
    });
    setDepartments(tempDeps);
    setDepartmentsLoading(false);
    setDepartmentsError("");
  };

  useEffect(() => {
    const fetchAll = async () => {
      await Promise.all([
        fetch(`${process.env.REACT_APP_AZURE_API_URL}/getPopularItems`)
          .then((res) => res.json())
          .then((data) => handleProductsAPI(data))
          .catch((e) => {
            console.log('Products error: '+e)
            setProductsError("Oops! Something went wrong...");
            setProductsLoading(false);
          }),
        fetch(`${process.env.REACT_APP_AZURE_API_URL}/getAllDepartments`)
          .then((res) => res.json())
          .then((data) => handleDepartmentsAPI(data))
          .catch(() => {
            setDepartmentsError("Oops! Something went wrong...");
            setDepartmentsLoading(false);
          }),
      ])
      .then( () => {
        setPageLoading(false)
      });
    };

    fetchAll();
    document.title='Home  - This is a demo website'
  }, [setPageLoading]);

  const productsRender = (
    <>
      {productsLoading && !productsError && (
        <div className="flex_fullWidth_center" style={{ marginBottom: 20, paddingTop: imgLoaded? 0: '25vw' }}>
          <Skeleton width="95%" height="50vh" variant="rectangular" />
        </div>
      )}
      {!productsLoading && !productsError && (
        <div className="flex_fullWidth_center">
          <HorizontalScrollContainer title="Popular items">
            {products}
          </HorizontalScrollContainer>
        </div>
      )}
      {productsError && !departmentsError && (
        <h1 style={{ textAlign: "center", color: "white" }}>
          {productsError}<br /><br />{":("}
        </h1>
      )}
    </>
  );

  const departmentsRender = (
    <>
      {departmentsLoading && !departmentsError && (
        <div className="flex_fullWidth_center">
          <Skeleton width="95%" height="70vh" variant="rectangular" />
        </div>
      )}
      {!departmentsLoading && !departmentsError && <div className="home__grid">{departments}</div>}
      {departmentsError && (
        <h1 style={{ textAlign: "center", color: "white" }}>
          {departmentsError}
          <br />
          <br />
          {":("}
        </h1>
      )}
    </>
  );

  return (
    <div className="home">
      <div className="home__container">
        <img src={bkgImg} className="home__image" alt="home background" onLoad={() => setImgLoaded(true)}/>
        {productsRender}
        {departmentsRender}
      </div>
    </div>
  );
}
