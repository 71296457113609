import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import MyTextField from "../UI/MyTextField";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import reqOptions from "../Helpers/POSTRequestOptions";


const SearchButton = styled(IconButton)(() => ({
  padding: 6,
  borderRadius: "0px 5px 5px 0",
  color: "rgb(51,51,51)",
  backgroundColor: "var(--myOrange)",
  "&:hover": {
    backgroundColor: "rgb(255,190,105)",
    boxSizing: "border-box",
  },
  "@media (hover: none)": {
    "&:hover": {
      color: "rgb(51,51,51)",
      backgroundColor: "var(--myOrange)",
    },
    "&:active": {
      backgroundColor: "rgb(255,190,105)",
      boxSizing: "border-box",
    },
  },
}));

export default function SearchBar() {
  const [inputText, setInputText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const history = useHistory();
  const windowLocation = useLocation();

  // listen for url change
  useEffect(() => {
    if (windowLocation.pathname !== "/search") {
      setInputText("");
    } else {
      const params = new URLSearchParams(window.location.search);
      setInputText(params.get("q"));
    }
  }, [windowLocation]);

  const enterHandler = (e, newValue) => {
    // handle keys that are not the "Enter" key
    if (!e || !(e.code === "Enter" || e.keyCode === "13" || e.keyCode === 13)) return;
    if (!newValue) {
      return;
    } else {
      setSearchOptions([]);
      history.push(`/search?q=${newValue}`);
    }
  };

  const handleChange = (e, newValue) => {
    setInputText(newValue);
    if (newValue) {
      fetch(`${process.env.REACT_APP_AZURE_API_URL}/searchSuggestions`, reqOptions({ searchValue: newValue }))
        .then((res) => res.json())
        .then((data) => setSearchOptions(data.matches));
    } else if (searchOptions.length > 0) {
      setSearchOptions([]);
    }
    enterHandler(e, newValue);
  };

  const handleSearch = () => {
    if (!inputText) {
      return;
    }
    history.push(`/search?q=${inputText}`);
  };

  return (
    <div className="flex_fullWidth_center">
      <Autocomplete
        fullWidth
        freeSolo
        size="small" //disableClearable
        options={searchOptions}
        value={inputText}
        onChange={handleChange}
        onInputChange={handleChange}
        onKeyDown={(e) => {
          enterHandler(e, inputText);
        }}
        // filterOptions={(x) => x}
        renderInput={(params) => <MyTextField {...params} placeholder="Search" />}
      />
      <SearchButton onClick={handleSearch} style={{ marginLeft: -1 }} size="large">
        <SearchIcon fontSize="inherit" />
      </SearchButton>
    </div>
  );
}
