import React from "react";
import { useHistory } from "react-router-dom";
import LinkTextButton from "../UI/LinkTextButton";
import classes from "./Breadcrumb.module.css";

export default function Breadcrumb({ activeStep }) {
  const history = useHistory();

  const cartHandler = () => {
    history.push("/cart");
  };

  const shippingHandler = () => {
    history.push("/checkout?currentStep=shipping");
  };

  const paymentHandler = () => {
    history.push("/checkout?currentStep=payment");
  };

  const arrowStyles = {margin: "0px 5px", color: 'gray'}

  return (
    <div className={classes.main}>
      <LinkTextButton color='gray' onClick={cartHandler}>Cart</LinkTextButton>
      <span style={arrowStyles}>{">"}</span>
      {activeStep === "shipping" ? (
        <span className={classes.text}>Shipping</span>
      ) : (
        <LinkTextButton color='gray' onClick={shippingHandler}>Shipping</LinkTextButton>
      )}
      <span style={arrowStyles}>{">"}</span>
      {activeStep === "payment" ? (
        <span className={classes.text}>Payment</span>
      ) : (
        <LinkTextButton color='gray' onClick={paymentHandler}>Payment</LinkTextButton>
      )}
    </div>
  );
}
