import React from "react";
import MyTextField from "../UI/MyTextField";
import classes from "./Checkout.module.css";

export default function AddressForm(props) {
  const {
    address,
    addressHandler,
    address2,
    address2Handler,
    country,
    countryHandler,
    state,
    stateHandler,
    city,
    cityHandler,
    postalCode,
    postalCodeHandler,
    textfieldSize,
  } = props;

  return (
    <>
      <div className={classes.row}>
        <MyTextField
          InputProps={{ autoComplete: "address-line1" }}
          value={address}
          onChange={addressHandler}
          style={{ width: "100%" }}
          label="Street address"
          size={textfieldSize}
          required
        />
      </div>
      <div className={classes.row}>
        <MyTextField
          InputProps={{ autoComplete: "address-line2" }}
          value={address2}
          onChange={address2Handler}
          style={{ width: "100%" }}
          label="Apt., suite, etc. (optional)"
          size={textfieldSize}
        />
      </div>
      <div className={classes.row}>
        <MyTextField
          InputProps={{ autoComplete: "country country-name" }}
          value={country}
          onChange={countryHandler}
          style={{ width: "100%" }}
          label="Country"
          size={textfieldSize}
          required
        />
      </div>
      <div className={classes.row}>
        <MyTextField
          InputProps={{ autoComplete: "address-level1" }}
          value={state}
          onChange={stateHandler}
          style={{ width: "100%", flex: 0.6, marginRight: "1.5%" }}
          label="State"
          size={textfieldSize}
          required
        />
        <MyTextField
          InputProps={{ autoComplete: "address-level2" }}
          value={city}
          onChange={cityHandler}
          style={{ width: "100%", flex: 0.6, marginRight: "1.5%" }}
          label="City"
          size={textfieldSize}
          required
        />
        <MyTextField
          inputProps={{
            maxLength: 10,
          }}
          InputProps={{ autoComplete: "postal-code" }}
          value={postalCode}
          onChange={postalCodeHandler}
          style={{ width: "100%", flex: 0.4 }}
          label="Postal code"
          size={textfieldSize}
          required
        />
      </div>
    </>
  );
}
