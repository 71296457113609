import { CircularProgress, /* LinearProgress, linearProgressClasses */} from "@mui/material";
import React from "react";
// import { styled } from "@mui/material/styles";
import Modal from "./Modal";

// const OrangeLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: "var(--myOrange)",
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     backgroundColor: "var(--lighterOrange)",
//   },
// }));

export default function MySpinner() {
  return (
    <Modal>
      <CircularProgress
        variant="indeterminate"
        sx={{
          color: "var(--myOrange)",
          position: "absolute",
          left: "50%",
          top: "50%",
        }}
        size={55}
        thickness={4}
      />
    </Modal>
  );
}
