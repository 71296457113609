import React, { useEffect, useState } from "react";
import "./HomeProduct.css";
import MyRating from "../UI/MyRating";
import MyButtonContained from "../UI/MyButtonContained";
import ProductPrice from "../UI/ProductPrice";
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";
import { useCart } from "../contexts/CartProvider";



export default function HomeProduct(props) {

    const cartCtx = useCart();
    const isDesktop = useMediaQuery({ query: `(min-width: 800px)` })
    const [buttonSize, setButtonSize] = useState('')
    const [buttonAnimation, setButtonAnimation] = useState('')
    const [myStyles, setMyStyles] = useState({})

    useEffect(() => {
      if(!isDesktop){
        setButtonSize('6px')
        setMyStyles({fontSize: 10})

      }else{
        setButtonSize('')
        setMyStyles({marginLeft: 5})
      }
    }, [isDesktop])

    const addToCartHandler = () => {

      const itemQuantity = 1;
      cartCtx.addItem({ id: props.id, price: props.price ,amount: itemQuantity })
      
      if(!isDesktop){
        setButtonAnimation('button_animation')
        const timer = setTimeout(() => { setButtonAnimation('') }, 400)
        return () =>{ clearTimeout(timer)} 
      }
    }

  return (
    <div className="product">
      <div className="product__container">
        <div className="product__info">
          <Link to={`/product/${props.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
            <p>{props.title}</p>
            <div className='product__details'>
              <ProductPrice price={props.price} theme='dark' letterSize={isDesktop ? '': 'mobile'} />
              <MyRating size="small" value={props.rating} ratingsList={props.ratingsList} readOnly precision={0.1} style={myStyles} />
            </div>
        </Link>
        </div>
        <Link to={`/product/${props.id}`}>
          <img src={`${props.img}/1.webp`} alt={props.title}/>
        </Link>
          <MyButtonContained className={buttonAnimation} style={{fontSize: buttonSize}} size="small" onClick={addToCartHandler}>Add to cart</MyButtonContained>
      </div>
    </div>
  );
}
