import React, { useEffect, useState } from "react";
import classes from "./ProductSpecs.module.css";

const handleProductSpecifications = (specs) => {
  let result = [];
  for (let [key, value] of Object.entries(specs)) {
    result.push([key, value]);
  }
  return result;
};

export default function ProductSpecs({ data }) {
  const [specs, setSpecs] = useState([]);

  useEffect(() => {
    setSpecs(handleProductSpecifications(data));
  }, [data]);

  return (
    <>
      {specs.length > 0 && (
        <div className={classes.main}>
          <table className={classes.table}>
            <tbody>
              {specs.map((el) => {
                return (
                  <tr key={el[0]}>
                    <td style={{ paddingRight: 30 }}>
                      <b>{el[0]}</b>
                    </td>
                    <td>{el[1]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
