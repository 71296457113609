import React, { useEffect } from "react";
import classes from "./Orders.module.css";
import { useLoading } from "../../contexts/LoadingProvider";
import MyTabs, { StyledTab } from "../../UI/MyTabs";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { useRef } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import Order from "./Order";

export default function Orders() {
  const [value, setValue] = useState(0);
  const [orders, setOrders] = useState([]);
  const [userName, setUserName] = useState([]);
  const [scrollButtons, setScrollButtons] = useState(false);
  const tabsRef = useRef(null);
  const authCtx = useAuth();
  const { setLoading: setPageLoading } = useLoading();

  const toggleScrollButton = () => {
    if (tabsRef.current) {
      if (!scrollButtons && tabsRef.current.offsetWidth <= 660) {
        setScrollButtons(true);
      } else if (scrollButtons && tabsRef.current.offsetWidth > 660) {
        setScrollButtons(false);
      }
    }
  };

  window.addEventListener("resize", toggleScrollButton, true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (authCtx.currentUser) {
      setUserName(authCtx.currentUser.displayName);
      fetch(`${process.env.REACT_APP_AZURE_API_URL}/getAllOrders/${authCtx.currentUser.uid}`)
        .then((res) => res.json())
        .then((data) => {
          setOrders(data.orders);
          setPageLoading(false);
        });
    }
  }, [setPageLoading, authCtx.currentUser]);

  return (
    <div className={classes.container}>
      <div ref={tabsRef} className={classes.main}>
        <Typography variant="h1" style={{ fontSize: "28px", fontWeight: 400 }}>
          Your Orders
        </Typography>

        <MyTabs
          scrollButtons={scrollButtons}
          variant="scrollable"
          allowScrollButtonsMobile
          className={classes.tabs}
          value={value}
          onChange={handleChange}
        >
          <StyledTab label="Orders" />
          <StyledTab label="Buy Again" />
          <StyledTab label="Not Yet Dispatched" />
          <StyledTab label="Local Store Orders" />
          <StyledTab label="Cancelled Orders" />
        </MyTabs>
        <div style={{marginTop: '2.5rem' }}>
          {orders.map((order) => {
            return <Order key={order.id} userName={userName} data={order} />;
          })}
        </div>
      </div>
    </div>
  );
}
