import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import classes from "./Checkout.module.css";
import AddressForm from "./AddressForm";
import CreditCardLogos from "./CreditCardLogos";
import { CardNoMask } from "./TextFieldMasks";
import LinkTextButton from "../UI/LinkTextButton";
import MyButtonContained from "../UI/MyButtonContained";
import MyTextField from "../UI/MyTextField";
import MyCheckbox from "../UI/MyCheckbox";
import useSimpleText from "../UI/FormComponents/useSimpleText";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { InputAdornment, Tooltip, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import inputNumbersOnly from "../Helpers/inputNumbersOnly";
import { useAuth } from "../contexts/AuthProvider";
import createProps from "../Helpers/simpleTextProps";
import reqOptions from "../Helpers/POSTRequestOptions";

const cardNoValidation = (val) => {
  let s = 0;
  let doubleDigit = false;
  for (let i = val.length - 1; i >= 0; i--) {
    let digit = +val[i];
    if (doubleDigit) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    s += digit;
    doubleDigit = !doubleDigit;
  }
  return s % 10 === 0;
};

const expirationDateValidation = (month, year) => {
  let today, someday;
  today = new Date();
  someday = new Date();
  someday.setFullYear(year, month, 1);

  if (someday < today) {
    return false;
  }
};

const cardNoSpecial = (val) => {
  return val.replace(/\s/g, "");
};

const cscValidation = (val) => {
  if (val.match(/^[0-9]+$/) != null || val === "") {
    return true;
  }
  return false;
};

const cscTooltipText = (
  <p>
    {"3-digit security code usually"}
    <br />
    {" found on the back of your card."}
    <br />
    {"American Express cards have a"}
    <br />
    {"4-digit code located on the front."}
  </p>
);

export default function Payment({ isMobile, calcHeight, cartCtx, setPageLoading }) {
  const [check, setCheck] = useState(true);
  const authCtx = useAuth();
  const [creditCardType, setCreditCardType] = useState("MASTERCARD");
  const [order, setOrder] = useState({ completed: false, id: "" });
  const [address, addressHandler] = useSimpleText(createProps(""));
  const [address2, address2Handler] = useSimpleText(createProps(""));
  const [country, countryHandler] = useSimpleText(createProps(""));
  const [state, stateHandler] = useSimpleText(createProps(""));
  const [city, cityHandler] = useSimpleText(createProps(""));
  const [postalCode, postalCodeHandler] = useSimpleText(createProps(""));
  const [cardNo, cardNoHandler, cardNoError] = useSimpleText(
    createProps("5555555555554444", cardNoValidation, cardNoSpecial)
  );
  const [name, nameHandler] = useSimpleText(createProps("John Doe"));
  const [expirationDate, , , setExpDate] = useSimpleText(
    createProps(new Date(), expirationDateValidation)
  );
  const [securityCode, securityCodeHandler, cscError] = useSimpleText(
    createProps("505", cscValidation, inputNumbersOnly)
  );

  const history = useHistory();
  const formRef = useRef(null);

  const textfieldSize = isMobile ? "small" : "";
  const returnToShipping = () => {
    history.push("/checkout?currentStep=shipping");
  };

  useEffect(() => {
    document.title = `Checkout - Payment details`;
  }, []);

  useEffect(() => {
    calcHeight(formRef.current);
  }, [check, formRef, calcHeight]);

  useEffect(() => {
    if (order.completed) {
      history.push(`/confirmationPage?orderId=${order.id}`);
    }
  }, [order, history]);

  const handleChange = (e) => {
    setCheck(e.target.checked);
  };

  const handleClick = () => {
    if (cartCtx.items.length === 0) {
      history.push("/");
      return;
    }
    setPageLoading(true);
    let payload = {
      userId: authCtx.currentUser.uid,
      email: authCtx.currentUser.email,
      data: cartCtx.items,
      domain: window.location.host,
    };
    fetch(`${process.env.REACT_APP_AZURE_API_URL}/completeOrder`, reqOptions(payload))
      .then((res) => res.json())
      .then((data) => {
        setTimeout(() => {
          cartCtx.completeOrder();
          setPageLoading(false);
          setOrder({ completed: true, id: data.orderId });
        }, 1500); // TODO: artificially adding wait time for completing an order
      });
  };

  return (
    <div ref={formRef} className={classes.form}>
      <div>
        <Typography variant="h5">Billing Address</Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <MyCheckbox checked={check} onChange={handleChange} size="small" />
        <span>Same as shipping address</span>
      </div>
      {!check && (
        <AddressForm
          address={address}
          addressHandler={addressHandler}
          address2={address2}
          address2Handler={address2Handler}
          country={country}
          countryHandler={countryHandler}
          state={state}
          stateHandler={stateHandler}
          city={city}
          cityHandler={cityHandler}
          postalCode={postalCode}
          postalCodeHandler={postalCodeHandler}
          textfieldSize={textfieldSize}
        />
      )}
      <div>
        <Typography variant="h5">Payment</Typography>
        <Typography variant="subtitle1" style={{ marginTop: "0.25rem", color: "#afafaf" }}>
          All transactions are secured and encrypted.
        </Typography>
        <CreditCardLogos
          cardNo={cardNo}
          creditCardType={creditCardType}
          setCreditCardType={setCreditCardType}
        />
      </div>
      <div className={classes.row}>
        <MyTextField
          InputProps={{ autoComplete: "cc-number", inputComponent: CardNoMask }}
          error={cardNoError}
          // helperText={cardNoError && "Incorrect card number"}
          value={cardNo}
          onChange={cardNoHandler}
          style={{ width: "100%" }}
          label="Card number"
          size={textfieldSize}
          required
        />
      </div>
      <div className={classes.row}>
        <MyTextField
          InputProps={{ autoComplete: "cc-name" }}
          value={name}
          onChange={nameHandler}
          style={{ width: "100%" }}
          label="Name on card"
          size={textfieldSize}
          required
        />
      </div>
      <div
        className={classes.row}
        style={{ marginRight: 8, justifyContent: "space-between", width: 375 }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year", "month"]}
            minDate={new Date()}
            InputProps={{ autoComplete: "cc-exp" }}
            inputFormat="MM/yyyy"
            label="Expiration Date"
            value={expirationDate}
            onChange={(newValue) => {
              setExpDate(newValue);
            }}
            renderInput={(params) => (
              <MyTextField style={{ width: 180 }} {...params} helperText={null} />
            )}
          />
        </LocalizationProvider>
        <MyTextField
          error={cscError}
          helperText={cscError && "Incorrect security code"}
          inputProps={{
            maxLength: creditCardType === "AMEX" ? 4 : 3,
          }}
          InputProps={{
            autoComplete: "cc-csc",
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={cscTooltipText}>
                  <HelpIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          value={securityCode}
          onChange={securityCodeHandler}
          style={{ width: 180 }}
          label="Security code"
          size={textfieldSize}
          required
        />
      </div>

      <div className={classes.buttons}>
        <div className={classes.return}>
          <LinkTextButton color="gray" onClick={returnToShipping}>
            <div className={classes.return}>
              <ArrowLeftIcon />
              {isMobile ? "Return" : "Return to shipping"}
            </div>
          </LinkTextButton>
        </div>
        <MyButtonContained onClick={handleClick}>Complete order</MyButtonContained>
      </div>
    </div>
  );
}
