import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./NavigationBar.css";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import MyButtonContained from "../UI/MyButtonContained";
import MyButtonOutlined from "../UI/MyButtonOutlined";
import MyTextButton from "../UI/MyTextButton";
import { useMediaQuery } from "react-responsive";
import MenuIcon from "@mui/icons-material/Menu";
import SearchBar from "./SearchBar";
import { useHistory, useLocation } from "react-router";
import { useCart } from "../contexts/CartProvider";
import AccountButton from "./AccountButton";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: -5,
    top: 3,
    backgroundColor: "var(--myOrange)",
    padding: "0 4px",
  },
}));

export default function NavigationBar({ authCtx, ...props }) {
  const history = useHistory();
  const cartCtx = useCart();
  const windowLocation = useLocation();
  const { items: cartItems } = cartCtx;
  const isDesktop = useMediaQuery({ query: `(min-width: 900px)` });
  const [cartAnimation, setCartAnimation] = useState("");
  const [userName, setUserName] = useState("");
  const [navOpen, setNavOpen] = useState(false);
  const [checkout, setCheckout] = useState();
  const numberofItemsInCart = cartItems.reduce((curNumber, item) => {
    return curNumber + item.amount;
  }, 0);

  useEffect(() => {
    setCheckout(windowLocation.pathname.includes("checkout"));
  }, [windowLocation]);

  useEffect(() => {
    if (authCtx.currentUser) {
      let name = authCtx.currentUser.displayName;
      if (name) {
        setUserName(name.split(" ")[0]);
      }
    }
  }, [authCtx.currentUser]);

  // Cart Animation
  useEffect(() => {
    if (cartItems.length === 0) return;
    setCartAnimation("bump");
    const timer = setTimeout(() => {
      setCartAnimation("");
    }, 300); // set timer
    return () => {
      clearTimeout(timer);
    }; // clear timer so multiple fast executions work
  }, [cartItems]);

  // close navbar
  useEffect(() => {
    if (navOpen && isDesktop) {
      setNavOpen(false);
    }
  }, [isDesktop, navOpen]);

  const menuClick = () => {
    setNavOpen((prevState) => !prevState);
  };

  const handleLogout = async () => {
    await authCtx.logout();
    history.push("/login");
  };

  const navLogo = (
    <Link to="/">
      <img
        style={{ margin: "0px 15px", maxWidth: 200, minWidth: 100 }}
        src={"/images/amazon_logo.png"}
        alt="company logo"
        width={isDesktop ? 180 : "100vw"}
      />
    </Link>
  );

  const navMenuButton = (
    <IconButton onClick={menuClick} size="large">
      <MenuIcon size="inherit" />
    </IconButton>
  );

  const mobileNav = (
    <>
      <div className="navbar__mobile">
        {navLogo}
        <SearchBar />
        {!checkout && (
          <StyledBadge
            className={`navbar__cart-mobile ${cartAnimation}`}
            badgeContent={numberofItemsInCart}
            color="secondary"
            invisible={numberofItemsInCart === 0}
            onClick={props.showCartHandler}
          >
            <ShoppingCartIcon />
          </StyledBadge>
        )}
        {!isDesktop && navMenuButton}
      </div>
      <div className={`navbar__content ${navOpen ? "visible" : "invisible"}`}>
        {authCtx.currentUser ? (
          <>
            <p style={{ height: 36, marginLeft: 6 }}>Hello, {userName}!</p>
            <MyTextButton>Account</MyTextButton>
            <MyTextButton onClick={handleLogout}>Log out</MyTextButton>
          </>
        ) : (
          <>
            <MyTextButton component={Link} to="/login">
              Log in
            </MyTextButton>
            <MyTextButton component={Link} to="/signup">
              Sign Up
            </MyTextButton>
          </>
        )}
      </div>
    </>
  );

  const authNav = authCtx.currentUser ? (
    <>
      <AccountButton userName={userName}/>
      <MyButtonContained onClick={handleLogout}>Log Out</MyButtonContained>
    </>
  ) : (
    <>
      <MyButtonOutlined component={Link} to="/signup">
        Sign up
      </MyButtonOutlined>
      <MyButtonContained component={Link} to="/login">
        Log in
      </MyButtonContained>
    </>
  );

  return (
    <div className={`navbar__main ${navOpen ? "open" : ""} ${!isDesktop ? "mobile" : ""}`}>
      {isDesktop ? (
        <>
          {navLogo}
          <div className="navbar__search">
            <SearchBar />
          </div>

          <div className="navbar__auth">{authNav}</div>
          {!checkout && (
            <div className="navbar__cart" onClick={props.showCartHandler}>
              <StyledBadge
                max={99}
                className={cartAnimation}
                badgeContent={numberofItemsInCart}
                color="secondary"
                invisible={numberofItemsInCart === 0}
              >
                <ShoppingCartIcon />
              </StyledBadge>
              <div
                className="navbar__cart__text"
                style={{ marginLeft: numberofItemsInCart === 0 ? 10 : 20 }}
              >
                <span style={{ fontSize: 12 }}>Shopping</span>
                <strong style={{ fontSize: 16 }}>Cart</strong>
              </div>
            </div>
          )}
        </>
      ) : (
        <>{mobileNav}</>
      )}
    </div>
  );
}
