import React, { useEffect, useState } from "react";
import Modal from "../UI/Modal";
import MyButtonContained from "../UI/MyButtonContained";
import MyButtonOutlined from "../UI/MyButtonOutlined";
import classes from "./QuickCart.module.css";
import QuickCartItem from "./QuickCartItem";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { useCart } from "../contexts/CartProvider";
import reqOptions from "../Helpers/POSTRequestOptions";
import { useLoading } from "../contexts/LoadingProvider";

export default function QuickCart(props) {
  const isDesktop = useMediaQuery({ query: `(min-width: 600px)` });
  const cartCtx = useCart();
  const history = useHistory();
  const [cartTotal, setCartTotal] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [noItems, setNoItems] = useState(0);
  const emptyCart = cartCtx.items.length === 0;
  const { loading, setLoading: setPageLoading } = useLoading();

  useEffect(() => {
    setPageLoading(true);
    fetch(`${process.env.REACT_APP_AZURE_API_URL}/updateCartData`, reqOptions({ cartData: cartCtx.items }))
      .then((res) => res.json())
      .then((data) => {
        setCartItems(data.cartData);
        setCartTotal(data.cartTotal);
      })
      .then(() => {
        setPageLoading(false);
      });
  }, [cartCtx.items, setPageLoading]);

  useEffect(() => {
    setNoItems(
      cartItems.reduce((cur, item) => {
        return cur + item.amount;
      }, 0)
    );
  }, [cartItems]);

  const cartAddItemHandler = (item, e) => {
    cartCtx.addItem({ ...item, amount: 1 });
  };
  const cartRemoveItemHandler = (id) => {
    cartCtx.removeItem(id);
  };

  const quickCartItems = (
    <>
      {cartItems.map((item) => (
        <QuickCartItem
          key={item.id}
          price={item.price}
          title={item.title}
          amount={item.amount}
          onRemove={cartRemoveItemHandler.bind(null, item.id)}
          onAdd={cartAddItemHandler.bind(null, item)}
        />
      ))}
    </>
  );

  const buttonStyles = { marginBottom: 15, fontSize: !isDesktop && "12px" };

  const checkoutHandler = () => {
    history.push("/checkout?currentStep=shipping");
    props.hideCartHandler();
  };

  return (
    <>
      {!loading && (
        <Modal card={true} onClose={props.hideCartHandler}>
          {!emptyCart && (
            <div className={classes["cart__header"]}>
              <h2 style={{ color: "white", marginBottom: 5, padding: 10 }}>Quick view</h2>
              <span style={{ marginRight: 3 }}>Price</span>
            </div>
          )}

          <div className={classes["cart__items"]}>{quickCartItems}</div>

          {emptyCart ? (
            <div className={classes["cart__empty"]}>
              <ShoppingCartOutlinedIcon style={{ fontSize: "5rem" }} />
              <h2>Empty cart</h2>
            </div>
          ) : (
            <div className={classes["cart__total"]}>
              <span>
                Subtotal ({noItems} {noItems === 1 ? "item" : "items"}) :{" "}
                <strong>
                  ${cartTotal.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                </strong>
              </span>
            </div>
          )}

          <div className={classes["cart__buttons"]}>
            {isDesktop && (
              <MyButtonOutlined outlineColor="gray" onClick={props.hideCartHandler}>
                Close
              </MyButtonOutlined>
            )}
            {!emptyCart && isDesktop && (
              <div>
                <MyButtonOutlined
                  style={{ marginRight: 15 }}
                  onClick={props.hideCartHandler}
                  outlineColor="orange"
                  component={Link}
                  to="/cart"
                >
                  Go To Cart
                </MyButtonOutlined>
                <MyButtonContained onClick={checkoutHandler}>Checkout</MyButtonContained>
              </div>
            )}
            {!emptyCart && !isDesktop && (
              <div className={classes.buttons_mobile}>
                <MyButtonContained style={buttonStyles} onClick={checkoutHandler}>
                  Checkout
                </MyButtonContained>
                <MyButtonOutlined
                  style={buttonStyles}
                  onClick={props.hideCartHandler}
                  outlineColor="orange"
                  component={Link}
                  to="/cart"
                >
                  Go To Cart
                </MyButtonOutlined>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}
