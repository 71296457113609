import React, { useEffect, useState } from "react";
import classes from "./CartSummary.module.css";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import reqOptions from "../Helpers/POSTRequestOptions";


const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 2,
    top: 1,
    //   border: `1px solid rgb(118, 118, 118)`,
    backgroundColor: "var(--myOrange)",
    //   color: 'white',
    padding: "0 4px",
  },
}));

function SummaryItem(props) {
  return (
    <div className={classes.item}>
      <div className={classes.item__img__container}>
        <StyledBadge className={classes.item__img} badgeContent={props.amount} color="secondary">
          <img src={`${props.img}/1.webp`} alt={props.title} />
        </StyledBadge>
      </div>
      <div className={classes.item__details}>
        <strong>{props.title}</strong>
        {/* <p>Amount: {props.amount}</p> */}
      </div>
      <div className={classes.item__price}>
        <strong>{`$${props.price.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}</strong>
      </div>
    </div>
  );
}

const handleCartItems = (items) => {
  let test = items.map((el, idx) => {
    return (
      <div key={idx}>
        <SummaryItem key={idx} title={el.title} price={el.price} img={el.img} amount={el.amount} />
        {idx < items.length - 1 && (
          <Divider key={el.title} width="100%" style={{ margin: "8px 0" }} />
        )}
      </div>
    );
  });
  return test;
};

export default function CartSummary({ cartCtx, setPageLoading }) {
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [noItems, setNoItems] = useState(0);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_AZURE_API_URL}/updateCartData`, reqOptions({ cartData: cartCtx.items }))
      .then((res) => res.json())
      .then((data) => {
        setCartItems(handleCartItems(data.cartData));
        setCartTotal(data.cartTotal);
        setNoItems(
          data.cartData.reduce((cur, item) => {
            return cur + item.amount;
          }, 0)
        );
        return data.cartData;
      })
      .then((data) => {
        if (data.length !== 0) {
          setPageLoading(false);
        }
      });
  }, [cartCtx.items, setPageLoading]);

  return (
    <div className={classes.container}>
      <div className={classes.summary}>{cartItems}</div>
      <div className={classes.total}>
        <div className={classes.row}>
          <span>
            Subtotal ({noItems} {noItems === 1 ? "item" : "items"})
          </span>
          <strong>
            {`$${Math.abs(cartTotal).toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
          </strong>
        </div>
        <div className={classes.row}>
          <span>Shipping</span>
          <strong>$0.00</strong>
        </div>
        <div className={classes.row}>
          <span>Taxes</span>
          <strong>$0.00</strong>
        </div>
        <div className={classes.row__total}>
          <span>Total</span>
          <strong>
            USD {`$${Math.abs(cartTotal).toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
          </strong>
        </div>
      </div>
    </div>
  );
}
