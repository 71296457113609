import React, { useState } from "react";
import "./App.css";
import { Switch, Route, HashRouter } from "react-router-dom";
import { Redirect } from "react-router";
import NavigationBar from "./Navigation/NavigationBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CategoriesBar from "./Navigation/CategoriesBar";
import QuickCart from "./QuickCart/QuickCart";
import CartProvider from "./contexts/CartProvider";
import Home from "./Home/Home";
import Cart from "./Cart/Cart";
import SearchResults from "./SearchResults/SearchResults";
import NotFound from "./NotFound";
import Login from "./Auth/Login";
import SignUp from "./Auth/SignUp";
import { useAuth } from "./contexts/AuthProvider";
import ProductPage from "./ProductPage/ProductPage";
import ScrollToTop from "./ScrollToTop";
import LoadingProvider from "./contexts/LoadingProvider";
import Checkout from "./Checkout/Checkout";
import Confirmation from "./Confirmation/Confirmation";
import Account from "./Account/Account";
import Orders from "./Account/Orders/Orders";
import PrivateRoute from "./Auth/PrivateRoute";

const darkTheme = createTheme({ palette: { mode: "dark" } });

function App() {
  const authCtx = useAuth();
  const [cartShown, setCartShown] = useState(false);
  const showCartHandler = () => {setCartShown(true);};
  const hideCartHandler = () => {setCartShown(false);};

  // document.body.onmousedown = e => { if (e.button === 1) return false; };

  return (
    <CartProvider>
      <ThemeProvider theme={darkTheme}>
        <HashRouter>
          <ScrollToTop>
            <LoadingProvider>
              <Switch>
                <Route exact path="/login">
                  {authCtx.currentUser ? <Redirect to="/" /> : <Login />}
                </Route>
                <Route exact path="/signup">
                  {authCtx.currentUser ? <Redirect to="/" /> : <SignUp />}
                </Route>
                <Route path="/">
                  <NavigationBar showCartHandler={showCartHandler} authCtx={authCtx} />
                  <CategoriesBar />
                  {cartShown && <QuickCart hideCartHandler={hideCartHandler} />}
                </Route>
              </Switch>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/cart" component={Cart} />
                <Route exact path="/search" component={SearchResults} />
                <Route exact path="/login" /> {/* Added here to avoid going to the not found page */}
                <Route exact path="/signup" /> {/* Added here to avoid going to the not found page */}
                <Route exact path="/product/:productId" component={ProductPage} />
                <Route exact path="/checkout" component={Checkout} />
                <Route exact path="/confirmationPage" component={Confirmation} />
                <PrivateRoute exact path="/account/orders" component={Orders} />
                <PrivateRoute exact path="/account" component={Account} />
                <Route path="*" component={NotFound} />
              </Switch>
            </LoadingProvider>
          </ScrollToTop>
        </HashRouter>
      </ThemeProvider>
    </CartProvider>
  );
}

export default App;
