import React from "react";
import classes from "./Thumbnails.module.css";


const handleImgError = (event) => event.target.style.display = 'none';

export default function Thumbnails(props) {

  const handleClick = (val) => {
    props.setThumbnails(prev => {
      let newArray = []
      let tempThumb;
      for(let i in prev){
        tempThumb = prev[i]
        tempThumb.selected = tempThumb.img === val.img
        newArray.push(tempThumb)
      }
      return newArray
    })
    props.setMainImage(prev => {
      let temp = prev.split('/')
      temp.pop()
      temp.push(val.img)
      temp = temp.join('/')
      return temp
    })
  }

  return (
    <div className={classes["product__thumbnails"]}>
      {props.data.map((thumb) => {
        return (
          <img
            key={`${thumb.img}${thumb.selected}`}
            onClick={handleClick.bind(null, thumb)}
            src={`${props.productPath}/${thumb.img}`}
            width={35}
            height={35}
            onError={handleImgError}
            alt={thumb.img}
            style={{
              objectFit: "contain",
              padding: 4,
              cursor: 'pointer',
              border: thumb.selected ? "1px solid orange" : "1px solid gray",
            }}
          />
        );
      })}
    </div>
  );
}
