import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase.js";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateProfile } from "firebase/auth";

const AuthContext = React.createContext({
  currentUser: undefined,
  login: (email, password) => {},
  signup: (email, password) => {},
  logout: () => {},
  resetPassword: (email) => {},
  updateEmail: (emai) => {},
  updatePassword: (password) => {},
}); // for vs-code auto-complete

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setAuthLoading(false);
    });
    return unsubscribe; // unsubscribe from listener when we unmount the component
  }, []);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password); // change this if I want to use my own server
  }

  function updateUserProfile(displayName) {
    return updateProfile(auth.currentUser, {
      displayName,
    });
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password); // change this if I want to use my own server
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  // values exporting to children below
  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    updateUserProfile,
  };

  return <AuthContext.Provider value={value}>{!authLoading && children}</AuthContext.Provider>;
}
