import { Card, CardContent, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./SignUp.css";
import { Link } from "react-router-dom";
import MyTextField from "../UI/MyTextField";
import MyButtonContained from "../UI/MyButtonContained";
import MyTextButton from "../UI/MyTextButton";
import { useAuth } from "../contexts/AuthProvider";
import { useHistory } from "react-router";
import { useLoading } from "../contexts/LoadingProvider";
import PasswordInput from "./PasswordInput";

export default function SignUp() {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputPass, setInputPass] = useState("");
  const [inputConfirmPass, setInputConfirmPass] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [passError, setPassError] = useState("");
  const history = useHistory();
  const authCtx = useAuth();
  const { setLoading: setPageLoading } = useLoading();

  useEffect(() => {
    document.title = `Sign-Up Page - This is a demo website`;
    setPageLoading(false);
  }, [setPageLoading]);

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPassError("");
    if (inputPass !== inputConfirmPass) {
      setPassError("Passwords do not match");
      setLoading(false);
      return;
    }
    try {
      setSignUpError("");
      await authCtx.signup(inputEmail, inputPass); // await works only inside async functions
      await authCtx.updateUserProfile(`${firstName} ${lastName}`)
      setLoading(false);
      history.push("/login"); // after finishing without errors redirect to Log In page
    } catch (error) {
      let firebaseMessage = error.message.split(" ").slice(1, -1).join(" ");
      setSignUpError("Failed to create an account. " + firebaseMessage);
      setLoading(false);
    }
  };

  const handleChange = (setter, e) => {
    setter(e.target.value);
  };
  const handleFirstName = handleChange.bind(null, setFirstName);
  const handleLastName = handleChange.bind(null, setLastName);
  const handleEmail = handleChange.bind(null, setInputEmail);
  const handlePass = handleChange.bind(null, setInputPass);
  const handleConfirmPass = handleChange.bind(null, setInputConfirmPass);

  const navLogo = (
    <Link to="/">
      <img
        style={{ width: "20vw", maxWidth: 200, minWidth: 100, marginBottom: 10 }}
        src={"/images/amazon_logo.png"}
        alt="company logo"
      />
    </Link>
  );

  return (
    <div className="signup__container">
      {navLogo}
      <Card className="signup__card">
        <CardContent className="signup__content">
          <div className="signup__header">
            <h1>Create a new account</h1>
            {loading && <CircularProgress style={{ marginLeft: 20, color: "var(--myOrange)" }} />}
          </div>
          <form onSubmit={handleSignup}>
            <MyTextField
              InputProps={{ autoComplete: "given-name" }}
              value={firstName}
              onChange={handleFirstName}
              className="signup__input"
              label="First name"
              required
            />
            <MyTextField
              InputProps={{ autoComplete: "family-name" }}
              value={lastName}
              onChange={handleLastName}
              className="signup__input"
              label="Last Name"
              required
            />
            <MyTextField
              InputProps={{ autoComplete: "email" }}
              value={inputEmail}
              onChange={handleEmail}
              className="signup__input"
              label="Email"
              required
            />
            <PasswordInput
              id="create-new-pass"
              InputProps={{ autoComplete: "new-password" }}
              newPassword={true}
              error={passError ? true : false}
              helperText={passError}
              value={inputPass}
              onChange={handlePass}
              className="signup__input"
              label="Password"
              required
            />
            <PasswordInput
              id="confirm-new-pass"
              InputProps={{ autoComplete: "new-password" }}
              newPassword={true}
              error={passError ? true : false}
              helperText={passError}
              value={inputConfirmPass}
              onChange={handleConfirmPass}
              className="signup__input"
              label="Confirm password"
              required
            />
            {signUpError && <p style={{ color: "#ff3915", marginTop: 20 }}>{signUpError}</p>}
            <MyButtonContained style={{ marginTop: 35 }} type="submit" fullWidth>
              Sign Up
            </MyButtonContained>
            <MyTextButton component={Link} to="/login" style={{ marginTop: 10 }} fullWidth>
              Already a user? Sign in
            </MyTextButton>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
