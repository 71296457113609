import React from "react";
import ReactDOM from "react-dom";
import "../UI/Modal.css";

const Backdrop = (props) => {
  return <div className="modal-backdrop" onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={props.card ? 'modal': 'modal-spinner'}>
      <div>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById("modal-overlay");

export default function Modal(props) {
  return (
    <>
      {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay card={props.card}>{props.children}</ModalOverlay>,
        portalElement
      )}
    </>
  );
}
