import React, { useState } from "react";
import { Drawer } from "@mui/material";
import PriceFilter from "./Filters/PriceFilter";
import RatingFilter from "./Filters/RatingFilter";
import "./FilterDrawer.css";
import MyButtonContained from "../UI/MyButtonContained";
import MyButtonOutlined from "../UI/MyButtonOutlined";

export default function FilterDrawer(props) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && event.key in ["Tab", "Shift"]) {
      return;
    }
    setOpen(open);
  };

  return (
    <div>
      {!open && (
        <MyButtonContained onClick={toggleDrawer(true)}>
          Filters{props.filterCount > 0 && ` (${props.filterCount})`}
        </MyButtonContained>
      )}
      <Drawer anchor={"left"} open={open} onClose={toggleDrawer(false)}>
        <div className="drawer__container">
          <RatingFilter filterOptions={props.filterOptions} setFilterOptions={props.setFilterOptions}/>
          {Object.keys(props.minMaxPrice).length > 0 && (
            <PriceFilter
              filterOptions={props.filterOptions}
              setFilterOptions={props.setFilterOptions}
              minMaxPrice={props.minMaxPrice}
            />
          )}
          <div className='flex_fullWidth_center'>
            {props.filterCount > 0 &&
            <MyButtonOutlined onClick={props.handleFilterReset} fullWidth>
              {`Clear Filters (${props.filterCount})`}
            </MyButtonOutlined>
            }
          </div>
        </div>
      </Drawer>
    </div>
  );
}
