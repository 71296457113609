import React from "react";
import { Autocomplete } from "@mui/material";
import MyTextField from "../UI/MyTextField";
import classes from "./Sorting.module.css";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const options = [
  { value: "views", label: "Popularity" },
  { value: "price", label: "Price" },
  { value: "rating", label: "Rating" },
  { value: "title", label: "Title" },
];

export default function Sorting(props) {
  const { sortField, setSortField, sortOrder, setSortOrder } = props;

  const handleChange = (_e, newVal) => {
    setSortField(options.filter((el) => el.label === newVal)[0].value);
  };

  const handleClick = () => {
    setSortOrder((prev) => {
      if (prev === "asc") {
        return "desc";
      }
      return "asc";
    });
  };

  const arrowClass = `${classes.arrow} ${sortOrder === "asc" ? classes.asc : classes.desc}`;

  return (
    <div className={classes.main}>
      <Autocomplete
        size="small" //disableClearable
        options={options.map((el) => el.label)}
        filterOptions={(options, state) => options}
        value={options.filter((el) => el.value === sortField)[0].label}
        onChange={handleChange}
        style={{ minWidth: 90, maxWidth: 165, width: "100%" }}
        renderInput={(params) => <MyTextField {...params} label="Sort" />}
      />
      <div className={arrowClass} onClick={handleClick} style={{ marginLeft: 5 }}>
        <ArrowUpwardIcon />
      </div>
      <span onClick={handleClick} className={classes.text}>
        {sortOrder.toUpperCase()}
      </span>
    </div>
  );
}
