import React, { useState } from "react";
import "./CategoriesBar.css";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer } from "@mui/material";
import { Box } from "@mui/system";
import ListItem from "@mui/material/ListItem";
// import ListItemIcon from "@mui/material/ListItemIcon";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { useHistory } from "react-router";

const StyledButton = styled(Button)(() => ({
  color: "white",
  fontSize: "12px",
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "rgba(202, 202, 202, 0.08)",
  },
}));

export default function CategoriesBar() {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [departments, setDepartments] = useState([]);
  const buttonLabels = ["Best Deals", "New Arrivals", "Top Selling"].map((el) => {
    return (
      <StyledButton key={el} variant="text">
        <span className="categories__text">{el}</span>
      </StyledButton>
    );
  });

  const closeDrawer = () => {
    setOpen(false);
  };
  const openDrawer = () => {
    if (departments.length === 0) {
      fetch(`${process.env.REACT_APP_AZURE_API_URL}/getAllDepartments`)
        .then((res) => res.json())
        .then((data) => {
          setDepartments(data.departments);
        })
        .then(() => { setOpen(true); });
    } else {
      setOpen(true);
    }
  };

  const gridStyle = { gridTemplateColumns: `repeat(${buttonLabels.length + 1},max-content)` };

  return (
    <div className="categories__main">
      <div className="categories__content" style={gridStyle}>
        <StyledButton
          style={{ display: "flex", alignItems: "center", padding: 0, height: "30px" }}
          variant="text"
          onClick={openDrawer}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <MenuIcon style={{ padding: 0, margin: 0 }} />
            All
          </span>
        </StyledButton>
        {buttonLabels}
        <Drawer anchor="left" open={open} onClose={closeDrawer}>
          <CloseIcon className="close__icon" onClick={closeDrawer} />
          <Box sx={{ width: 250 }} onClick={closeDrawer} onKeyDown={closeDrawer}>
            <div className="flex_fullWidth_center" style={{ padding: "10px 0px", margin: 0 }}>
              <h3>Departments</h3>
            </div>
            <Divider />
            <List>
              {departments.map((item) => (
                <ListItem
                  button
                  key={item.id}
                  onClick={() => {
                    history.push(`/search?dep=${item.id}`);
                  }}
                >
                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </div>
    </div>
  );
}
