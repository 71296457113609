import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLoading } from "../contexts/LoadingProvider";
import classes from "./Confirmation.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LinkTextButton from "../UI/LinkTextButton";
import { useMediaQuery } from "react-responsive";

export default function Confirmation() {
  const windowLocation = useLocation();
  const history = useHistory();
  const [orderId, setOrderId] = useState();
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });

  const { setLoading: setPageLoading } = useLoading();

  useEffect(() => {
    document.title = `Order confirmation`;
    const params = new URLSearchParams(windowLocation.search);
    let id = params.get("orderId");
    if (id) {
      document.title = `Order confirmation - ${id}`;
      setOrderId(id);
      fetch(`${process.env.REACT_APP_AZURE_API_URL}/getOrder/${id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setError(true);
          }
        })
        .catch((e) => {
          console.log(e);
          setError(true);
        });
    }
    setPageLoading(false);
  }, [windowLocation, setPageLoading]);

  const handleClick = () => {
    history.push("/");
  };

  const success = (
    <>
      <div className={classes.header}>
        <CheckCircleIcon fontSize={isMobile ? "medium" : "large"} color="success" />
        <h1>Order confirmed!</h1>
      </div>
      <div className={classes.content}>
        <p className={classes.text}>
          {/* Our trained squirrels are processing your oder.<br /> */}
          We want to personally thank you for choosing to use our platform!
          {!isMobile && <br />}
          {" Please don't hesitate to contact us if you have any questions."}
          {/* We hope you enjoyed your time browsing our items and found what you were looking for.<br/> */}
          <br />
          We wish to see you again soon.
        </p>
        <p>
          <strong>Order ID</strong>
          <span style={{ marginRight: 5 }}>:</span>
          <span>{orderId}</span>
        </p>
        <div className={classes.button}>
          <LinkTextButton color="orange" onClick={handleClick}>
            Return to home
          </LinkTextButton>
        </div>
      </div>
    </>
  );

  const fail = (
    <>
      <div className={classes.header}>
        <CancelIcon fontSize={isMobile ? "medium" : "large"} color="error" />
        <h1>Unexpected error</h1>
      </div>
      <div className={classes.content}>
        <p className={classes.text}>
          We were not able to confirm your order.
          <br />
          Please try again later.
        </p>
        <div className={classes.button}>
          <LinkTextButton color="orange" onClick={handleClick}>
            Return to home
          </LinkTextButton>
        </div>
      </div>
    </>
  );

  return (
    <div className={classes.container}>
      <div
        className={classes.card}
        style={{ borderColor: error ? "rgba(244,67,52,.5)" : "rgba(102,187,106.5)" }}
      >
        {error ? fail : success}
      </div>
    </div>
  );
}
