import React from "react";
import { styled } from "@mui/material/styles";
import { Slider } from "@mui/material";

const StyledSlider = styled(Slider)(() => ({
  color: "var(--myOrange)",
  ".MuiSlider-thumb": {
    color: "var(--myOrange)",
    "&.Mui-focusVisible": {
      boxShadow: "0px 0px 0px 0px rgba(255, 165, 0, .16)",
    },
    "&.Mui-active": {
      boxShadow: "0px 0px 0px 10px rgba(255, 165, 0, .16)",
    },
  },
}));

export default function MySlider(props) {
  const { children, ...params } = props;
  return <StyledSlider {...params}>{children}</StyledSlider>;
}
