import React from "react";
import { Link } from "react-router-dom";
import "./DepartmentCard.css";

export default function DepartmentCard(props) {
  return (
    <div className="department">
      <div className="department__container">
        <h2 style={{ width: "100%", marginBottom: 10 }}>{props.name}</h2>
        <div className="department__info">
          <Link className="department__info" to={`/search?dep=${props.id}`}>
            <img src={props.img} alt={props.name} />
          </Link>
        </div>
        <Link to={`/search?dep=${props.id}`}>See more</Link>
      </div>
    </div>
  );
}
