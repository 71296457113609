import { Rating, Typography } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { styled } from "@mui/system";
import RatingRow from "./RatingRow";

const StyledStartIcon = styled(StarBorderIcon)(() => ({ color: "orange" }));

const generateRandomRatings = (ratings) => {
  const res = [];

  for (const [index, element] of ratings.entries()) {
    res.push(<RatingRow key={index} starValue={ratings.length - index} percentage={element} />);
  }
  return res;
};

export default function TooltipContent({ randomRatings, ...props }) {
  return (
    <div style={{ padding: "10px 5px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Rating
          {...props}
          emptyIcon={<StyledStartIcon fontSize="inherit" />}
          style={{ marginRight: 5 }}
        />
        <b style={{ fontSize: "1rem" }}>{`${props.value} out of 5`}</b>
      </div>
      <Typography color="">500 total ratings</Typography>
      {generateRandomRatings(randomRatings)}
    </div>
  );
}
