import React, { useEffect, useState } from "react";
import PriceFilter from "./Filters/PriceFilter";
import RatingFilter from "./Filters/RatingFilter";
import SearchItem from "./SearchItem";
import "./SearchResults.css";
import { useMediaQuery } from "react-responsive";
import FilterDrawer from "./FilterDrawer";
import ReactDOM from "react-dom";
import MyButtonOutlined from "../UI/MyButtonOutlined";
import { useLocation } from "react-router-dom";
import { useLoading } from "../contexts/LoadingProvider";
import calculateMinMax from "../Helpers/CalculateMinMax";
import filterData from "../Helpers/FilterSearchResults";
import getDepName from "../Helpers/GetDepartureNameFromId";
import reqOptions from "../Helpers/POSTRequestOptions";
import Sorting from "./Sorting";

const handleResults = (data) => {
  return data.map((el, idx) => (
    <div key={el.id}>
      <SearchItem
        id={el.id}
        title={el.title}
        price={el.price}
        rating={el.rating}
        ratingsList={el.ratingsList}
        img={el.img}
      />
      {idx < data.length - 1 && <div className="divider" />}
    </div>
  ));
};

const initialFilters = { rating: null, price: null };

export default function SearchResults() {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState();
  const [minMaxPrice, setMinMaxPrice] = useState({});
  const [sortField, setSortField] = useState("title");
  const [sortOrder, setSortOrder] = useState("asc");
  const isDesktop = useMediaQuery({ query: `(min-width: 990px)` });
  const [filterCount, setFilterCount] = useState(0);
  const windowLocation = useLocation();
  const { setLoading: setPageLoading } = useLoading();

  useEffect(() => {
    const params = new URLSearchParams(windowLocation.search);
    if (params.get("q")) {
      document.title = `Search results for "${params.get("q")}"  - This is a demo website`;
    } else if (params.get("dep")) {
      getDepName(params.get("dep")).then((depName) => {
        document.title = `All items in ${depName} - This is a demo website`;
      });
    } else {
      document.title = `Search results - This is a demo website`;
    }
    let payload = [];
    for (const entry of params.entries()) {
      payload.push([...entry]);
    }
    fetch(`${process.env.REACT_APP_AZURE_API_URL}/filterData`, reqOptions({ payload }))
      .then((res) => res.json())
      .then((data) => {
        setResults(data.data);
        setFilteredResults(data.data);
        const [minPrice, maxPrice] = calculateMinMax(data.data);
        setMinMaxPrice({ min: minPrice, max: maxPrice });
        setFilterOptions({
          ...initialFilters,
          price: [minPrice, maxPrice],
        });
      })
      .then(() => {
        setPageLoading(false);
      });
  }, [windowLocation, setPageLoading]);

  // filter data based on filter options
  useEffect(() => {
    let filteredData;
    let sortedData;
    if (!filterOptions) {
      filteredData = results;
    } else {
      filteredData = filterData(results, filterOptions);
    }
    if (sortOrder === "asc") {
      sortedData = filteredData.sort((a, b) =>
        a[sortField] > b[sortField] ? 1 : b[sortField] > a[sortField] ? -1 : 0
      );
    } else {
      sortedData = filteredData.sort((a, b) =>
        a[sortField] > b[sortField] ? -1 : b[sortField] > a[sortField] ? 1 : 0
      );
    }
    setFilteredResults(sortedData);
  }, [results, filterOptions, sortField, sortOrder]);

  // active filters count
  useEffect(() => {
    if (!minMaxPrice) {
      return;
    }
    let count = 0;
    for (const key in filterOptions) {
      let tempVal = filterOptions[key];
      if (!tempVal) {
        continue;
      }
      if (tempVal !== null) {
        count++;
      }
      if (
        key === "price" &&
        minMaxPrice["min"] === tempVal[0] &&
        minMaxPrice["max"] === tempVal[1]
      ) {
        count--;
      }
    }
    setFilterCount(count);
  }, [filterOptions, minMaxPrice]);

  const handleFilterReset = () => {
    setFilterOptions({ ...initialFilters, price: [minMaxPrice["min"], minMaxPrice["max"]] });
  };

  const portalElement = document.getElementById("filter-overlay");

  const mobileFilterComponent = (
    <div className="mobile__filter">
      <FilterDrawer
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        minMaxPrice={minMaxPrice}
        handleFilterReset={handleFilterReset}
        filterCount={filterCount}
      />
    </div>
  );

  const resetFiltersButton = (
    <div>
      {filterCount > 0 && (
        <MyButtonOutlined onClick={handleFilterReset} fullWidth>
          {`Clear Filters (${filterCount})`}
        </MyButtonOutlined>
      )}
    </div>
  );

  return (
    <>
      <div className="results__container">
        <div className="results__header">
          {!isDesktop && ReactDOM.createPortal(mobileFilterComponent, portalElement)}
          <p style={{ marginLeft: "15%" }}>
            {filteredResults.length === 1
              ? "1 item found"
              : `${filteredResults.length} items found`}
          </p>
        </div>
        <div className="results__body">
          {isDesktop && (
            <div className="results__left">
              {Object.keys(minMaxPrice).length > 0 && filterOptions && (
                <div className="filter__container">
                  <RatingFilter filterOptions={filterOptions} setFilterOptions={setFilterOptions} />
                  <PriceFilter
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    minMaxPrice={minMaxPrice}
                  />
                  {resetFiltersButton}
                </div>
              )}
            </div>
          )}
          <div className="results__right">
            <Sorting
              sortField={sortField}
              setSortField={setSortField}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
            />
            {handleResults(filteredResults)}
          </div>
        </div>
      </div>
    </>
  );
}
