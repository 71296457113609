import React from "react";
import "./SearchItem.css";
import MyRating from "../UI/MyRating";
import ProductPrice from "../UI/ProductPrice";
import { Link, useHistory } from "react-router-dom";
import LinkTextButton from "../UI/LinkTextButton";

export default function SearchItem(props) {
  const history = useHistory();

  const handleClick = (link) => {
    history.push(link);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="searchitem__container">
        <div className="itemimage__container">
          <Link to={`/product/${props.id}`}>
            <img src={`${props.img}/1.webp`} alt="product" />
          </Link>
        </div>
        <div className="searchitem__details">
          <LinkTextButton color='white' onClick={handleClick.bind(null, `/product/${props.id}`)}>
            <h3>{props.title}</h3>
          </LinkTextButton>
          <div style={{ display: "flex", margin: "2px 0px" }}>
            <MyRating
              size="small"
              value={props.rating}
              ratingsList={props.ratingsList}
              readOnly
              precision={0.1}
            />
            500
          </div>
          <ProductPrice price={props.price} theme="dark" />
          <p style={{ fontSize: 14, color: "rgb(18 183 18)", marginTop: 10 }}>In Stock</p>
        </div>
      </div>
    </div>
  );
}
