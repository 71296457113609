import { useState } from "react";

const useSimpleText = (props) => {
  const [value, setValue] = useState(props.initialValue);
  const [error, setError] = useState(false);

  const changeHandler = (e) => {
    let newVal = e.target.value;
    if (props.specialFunc) {
      newVal = props.specialFunc(newVal);
    }
    setValue(newVal);
    setError(false);
    if (props.validationFunc) {
      setError(!props.validationFunc(newVal));
    }
  };

  return [
    value,
    changeHandler,
    error,
    setValue,
  ];
};

export default useSimpleText;
