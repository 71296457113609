import React from "react";
import classes from "./AccountButton.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import LinkTextButton from "../UI/LinkTextButton";
import { useHistory } from "react-router-dom";

const dropdownItems = [
  { text: "Your Account", link: "/account" },
  { text: "Your Orders", link: "/account/orders" },
];

export default function AccountButton({ userName }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const clickHandler = () => {
    setOpen(false);
    history.push("/account");
  };

  const handleMenuItemClick = (link) => {
    history.push(link);
  };

  const enterHandler = () => {
    setOpen(true);
  };

  const leaveHandler = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container} onMouseEnter={enterHandler} onMouseLeave={leaveHandler}>
      <div className={classes.main}>
        <p className={classes.top_text}>Hello, {userName}!</p>
        <div onClick={clickHandler} className={classes.bottom_text}>
          <span>Account</span>
          <ArrowDropDownIcon fontSize="inherit" />
        </div>
      </div>
      {open && (
        <div className={classes.dropdown}>
          <div className={classes.dropdown_content}>
            <h3>Your Account</h3>
            <div className={classes.dropdown_items}>
              {dropdownItems.map((item) => {
                return (
                  <LinkTextButton
                    color="lightGray"
                    key={item.text}
                    onClick={handleMenuItemClick.bind(null, item.link)}
                  >
                    {item.text}
                  </LinkTextButton>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
