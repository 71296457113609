import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(() => ({
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px rgba(255,199,122,0.1) inset",
      // WebkitTextFillColor: 'orange',
      transitionDelay: "9999s",
      transitionProperty: "background-color, color",
      caretColor: "pink",
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid orange",
    },
  },
}));
export default function MyTextField(props) {
  const { children, ...params } = props;
  return <StyledTextField {...params}>{children}</StyledTextField>;
}
