import React from "react";
import Rating from "@mui/material/Rating";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { styled } from "@mui/material/styles";
import RatingTooltip from "./RatingTooltip/RatingTooltip";

const StyledStartIcon = styled(StarBorderIcon)(() => ({
  color: "orange",
}));

export default function MyRating({disableTooltip, ...props}) {
  const { ratingsList, ...params} = props; // override the rating's value
  return (
    <>
      {disableTooltip ? (
        <Rating {...props} emptyIcon={<StyledStartIcon fontSize="inherit" />} />
      ) : (
        <RatingTooltip randomRatings={ratingsList} {...params}>
          <div>
            <Rating {...params} emptyIcon={<StyledStartIcon fontSize="inherit" />} />
          </div>
        </RatingTooltip>
      )}
    </>
  );
}
