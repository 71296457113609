import React, { useEffect } from "react";
import { ReactComponent as VisaLogo } from "./card_logos/Visa.svg";
import { ReactComponent as MastercardLogo } from "./card_logos/Mastercard.svg";
import { ReactComponent as AmexLogo } from "./card_logos/Amex.svg";
import { ReactComponent as DiscoverLogo } from "./card_logos/Discover.svg";
import getCreditCardType from "../Helpers/CreditCardType";
import classes from "./Checkout.module.css";

export default function CreditCardLogos({ creditCardType, cardNo, setCreditCardType }) {
  useEffect(() => {
    setCreditCardType(getCreditCardType(cardNo));
  }, [cardNo, setCreditCardType]);

  const visaClass =
    creditCardType && creditCardType !== "VISA" ? classes.disabled__credit__card : "";
  const mastercardClass =
    creditCardType && creditCardType !== "MASTERCARD" ? classes.disabled__credit__card : "";
  const amexClass =
    creditCardType && creditCardType !== "AMEX" ? classes.disabled__credit__card : "";
  const discoverClass =
    creditCardType && creditCardType !== "DISCOVER" ? classes.disabled__credit__card : "";
  return (
    <div className={classes.credit__cards}>
      <VisaLogo width={45} height={35} className={visaClass} />
      <MastercardLogo width={45} height={35} className={mastercardClass} />
      <AmexLogo width={45} height={35} className={amexClass} />
      <DiscoverLogo width={45} height={35} className={discoverClass} />
    </div>
  );
}
