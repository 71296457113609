import React from "react";
import { useHistory } from "react-router-dom";
import LinkTextButton from "../../UI/LinkTextButton";
import classes from "./Order.module.css";
import { useMediaQuery } from "react-responsive";

export default function Order({ data: order, userName }) {
  return (
    <div className={classes.order}>
      <div className={classes.header}>
        <div className={classes.header_left}>
          <div className={classes.header_column}>
            <p className={classes.column_title}>ORDER PLACED</p>
            <p className={classes.column_content}>{order.date}</p>
          </div>
          <div className={classes.header_column}>
            <p className={classes.column_title}>ORDER TOTAL</p>
            <p className={classes.column_content}>
              ${order.total.toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </p>
          </div>
          <div className={classes.header_column}>
            <p className={classes.column_title}>DISPATCHED TO</p>
            <p className={classes.column_content}>{userName}</p>
          </div>
        </div>
        <div className={classes.header_right}>
          <p>Order # {order.id}</p>
        </div>
      </div>
      <div className={classes.details}>
        {order.items.map((item) => {
          return <OrderItem key={item.id} data={item} />;
        })}
      </div>
    </div>
  );
}

const OrderItem = ({ data }) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 736px)` });
  const handleClick = () => {
    history.push(`/product/${data.id}`);
  };
  return (
    <div className={classes.item}>
      <div className={classes.item_left}>
        <div className={classes.item_left_img}>
          <img src={`/images/products/${data.img}/1.webp`} alt={data.title} />
        </div>
        <div className={classes.just_padding}>
          <LinkTextButton onClick={handleClick}>{data.title}</LinkTextButton>
          <p>Quantity: {data.amount}</p>
          {isMobile && <p>${data.price.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>}
        </div>
      </div>
      {!isMobile && (
        <div className={`${classes.item_right}  ${classes.just_padding}`}>
          <p>${data.price.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
        </div>
      )}
    </div>
  );
};
