import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(() => ({
  color: "var(--myOrange)",
  borderRadius: 0,
  '@media (hover: hover)':{
    '&:hover':{
        backgroundColor: 'rgba(255,190,105,0.15)',
    },
},
}));

export default function MyTextButton(props) {
  const { children, ...params } = props;

  return <StyledButton {...params}>{children}</StyledButton>;
}
