import React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import { Tab } from "@mui/material";

const StyledTabs = styled(Tabs)({
  borderBottom: "1px solid var(--borderColor)",
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--myOrange)",
  },
  ".MuiTabs-scrollButtons.Mui-disabled": {
    opacity: 0.3,
  },
});

export default function MyTabs(props) {
  const { children, ...params } = props;
  return <StyledTabs {...params}>{children}</StyledTabs>;
}

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: "#bcbcbc", // #007185 #42a6ff
  // fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"','Roboto','"Helvetica Neue"','Arial','sans-serif','"Apple Color Emoji"','"Segoe UI Emoji"','"Segoe UI Symbol"', ].join(','),
  "&:hover": {
    color: "var(--lighterOrange)",
    //   textDecoration: 'underline',
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "white", // #d1eaff
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));
