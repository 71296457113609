import React from "react";

export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "8%",
      }}
    >
      <h1 style={{ marginBottom: 20 }}>Error 404 - Page not found</h1>
      <iframe
        src="https://giphy.com/embed/14uQ3cOFteDaU"
        width="480"
        height="360"
        title="error 404"
        frameBorder="0"
        class="giphy-embed"
        allowFullScreen
        style={{ marginBottom: 10 }}
      />
      <p>
        <a href="https://giphy.com/gifs/404-14uQ3cOFteDaU">via GIPHY</a>
      </p>
    </div>
  );
}
