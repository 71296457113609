import React from "react";
import MyRating from "../../UI/MyRating";
import "./RatingFilter.css";

const ratings = [4, 3, 2, 1];

export default function RatingFilter({ filterOptions, setFilterOptions }) {
  const handleClick = (value) => {
    if (filterOptions.rating === value) {
      setFilterOptions((prevState) => {
        return { ...prevState, rating: null };
      });
    } else {
      setFilterOptions((prevState) => {
        return { ...prevState, rating: value };
      });
    }
  };

  const isSelected = (itemValue) => {
    if (!filterOptions || !filterOptions.rating) {
      return "";
    } else {
      return filterOptions.rating === itemValue ? "filter__selected" : "filter__disabled";
    }
  };

  return (
    <div className="rating__filter">
      <b style={{ marginBottom: 5 }}>Customer reviews</b>
      {ratings.map((ratingValue) => (
        <span
          key={ratingValue}
          className={`rating__text ${isSelected(ratingValue)}`}
          onClick={handleClick.bind(null, ratingValue)}
        >
          <MyRating
            key={ratingValue}
            size="small"
            value={ratingValue}
            readOnly
            precision={1}
            disableTooltip={true}
          />
          <span key={ratingValue * 100}> & Up</span>
        </span>
      ))}
    </div>
  );
}
