import React, { useRef } from "react";
import "./HorizontalScrollContainer.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from 'react-responsive';


const hoverActiveProps = {

  '@media (hover: hover)':{
    "&:hover": {
      color: "var(--myOrange)",
      cursor: "pointer",
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
    "&:active": {
      border: "1px solid white",
      backgroundColor: 'rgba(255,255,255,0.05)',
      margin:0,
    },
  },
  '@media (hover: none)':{
    "&:active": {
      color: "var(--myOrange)",
      border: "1px solid white",
      backgroundColor: 'rgba(255,255,255,0.05)',
      margin:0,
    },
  },
};


const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)(() => ({
  color: "#939393",
  margin:1,
  alignItems: 'center',
  padding: '10px 5px',
  borderRadius: '10px',
  ...hoverActiveProps,
}));

export default function HorizontalScrollContainer(props) {
  const gridRef = useRef();
  const scrollValue = gridRef.current ? gridRef.current.offsetWidth / 2 : 500;
  const isDesktop = useMediaQuery({ query: `(min-width: 800px)` })


  const handleLeftArrow = () => {
    gridRef.current.scrollLeft -= scrollValue;
  };

  const handleRightArrow = () => {
    gridRef.current.scrollLeft += scrollValue;
  };

  return (
    <div className="horizontal__container">
      <h1 style={{fontWeight: 'normal', color:'white', marginTop: 5, marginBottom: 5, marginLeft: 20 }}>{props.title}</h1>
      <div className='horizontal__main'>
        <div style={{transform: 'rotate(180deg)'}}>
        <StyledArrowForwardIosIcon fontSize={isDesktop ?"large" :'8px'} onClick={handleLeftArrow} />
        </div>
        <div ref={gridRef} className="horizontal__grid">
          {props.children}
        </div>
          <StyledArrowForwardIosIcon fontSize={isDesktop ?"large" :'8px'} onClick={handleRightArrow} />
      </div>
    </div>
  );
}
