import React from "react";
import LinkTextButton from "../UI/LinkTextButton";
import MyButtonContained from "../UI/MyButtonContained";
import "./SavedItem.css";
import { useHistory } from "react-router";

export default function SavedItem(props) {
  const history = useHistory();

  const handleProductClick = () => {
    history.push(`/product/${props.item.id}`);
  };
  return (
    <div className="saveditem__container">
      <div className="saveditem__image" onClick={handleProductClick}>
        <img src={`${props.item.img}/1.webp`} alt={props.item.title} />
      </div>
      <div className="saveditem__details">
        <span className="block-with-text" style={{ fontSize: "1rem", marginTop: 5 }}>
          {props.item.title}
        </span>
        <b style={{ margin: "10px 0px", fontSize: 18 }}>{`$${props.item.price.toFixed(2)}`}</b>
        <p style={{ fontSize: 14, color: "rgb(18 183 18)" }}>In Stock</p>
        <MyButtonContained
          onClick={props.moveToCart}
          className="saveditem__button"
          style={{ margin: "15px 0px" }}
        >
          Move to Cart
        </MyButtonContained>
        <LinkTextButton onClick={props.deleteSavedItem}>Delete</LinkTextButton>
      </div>
    </div>
  );
}
