import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MyTextButton from "../UI/MyTextButton";
import { Snackbar, SnackbarContent } from "@mui/material";

export default function CartSnackbar(props) {
  const { cartCtx, snackbarOpen, setSnackbarOpen } = props;

  const handleSnackbarUndo = (event, reason) => {
    if (reason === "clickaway") {
      return;
    } else if (reason === "timeout") {
      setSnackbarOpen(false);
      return;
    }
    setSnackbarOpen(false);
    cartCtx.revertChanges(cartCtx.prevState);
  };

  const action = (
    <>
      <MyTextButton color="inherit" size="small" onClick={handleSnackbarUndo}>
        UNDO
      </MyTextButton>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => { setSnackbarOpen(false) }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarUndo}
    >
      <SnackbarContent
        style={{ minWidth: "90%", color: "white", backgroundColor: "#232F3E" }}
        message="Item deleted"
        action={action}
      />
    </Snackbar>
  );
}
