import React, { Fragment } from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


const StyledButton = styled(Button)(() => ({
    color: 'darkgray',
    border: '1px solid darkgray',
    '&:hover':{
        border: '1px solid transparent',
        backgroundColor: 'rgb(255,190,105)',
        color: 'rgb(51,51,51)',
    },
    '@media (hover: none)':{
        '&:hover':{
            color: 'darkgray',
            border: '1px solid darkgray',
            backgroundColor: 'transparent',
        },
        '&:active':{
            border: '1px solid transparent',
            backgroundColor: 'rgb(255,190,105)',
            color: 'rgb(51,51,51)',
        }
    },
}));

const StyledOrangeButton = styled(Button)(() => ({
    color: 'var(--myOrange)',
    border: '1px solid var(--myOrange)',
    '&:hover':{
        border: '1px solid transparent',
        backgroundColor: 'rgb(255,190,105)',
        color: 'rgb(51,51,51)',
    }
}));


export default function MyButtonOutlined(props) {

    const { outlineColor, children, ...params } = props;

    return (
        <Fragment>
            { outlineColor === 'orange' ?
                <StyledOrangeButton variant='outlined' {...params}>
                    {children}
                </StyledOrangeButton>
            :
                <StyledButton variant='outlined' {...params}>
                    {children}
                </StyledButton>
            }
        </Fragment>
    )
}
